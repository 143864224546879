import React from "react";
import InputMask from 'react-input-mask';
import axios from "axios";
import Pagination from "./Pagination";
import { NumericFormat } from 'react-number-format';
import ImageWithFallback from "./chats/ImageWithFallback";
class ContactRoom  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tags: [],
            tagContacts: {},
            contacts: {},
            contactsCount: [],
            inputTitle: '',
            selectedTag: null,
            name: '',
            email: '',
            phone: '',
            tagSelected: '',
            phoneType: 'mobile',
            interest: '',
            numProcess: '',
            shareValue: '0',
            address: '',
            city: '',
            state: '',
            cep: '',
            contactIdSelected: '',
            estadosBrasil: [
                { sigla: 'AC', nome: 'Acre' },
                { sigla: 'AL', nome: 'Alagoas' },
                { sigla: 'AP', nome: 'Amapá' },
                { sigla: 'AM', nome: 'Amazonas' },
                { sigla: 'BA', nome: 'Bahia' },
                { sigla: 'CE', nome: 'Ceará' },
                { sigla: 'DF', nome: 'Distrito Federal' },
                { sigla: 'ES', nome: 'Espírito Santo' },
                { sigla: 'GO', nome: 'Goiás' },
                { sigla: 'MA', nome: 'Maranhão' },
                { sigla: 'MT', nome: 'Mato Grosso' },
                { sigla: 'MS', nome: 'Mato Grosso do Sul' },
                { sigla: 'MG', nome: 'Minas Gerais' },
                { sigla: 'PA', nome: 'Pará' },
                { sigla: 'PB', nome: 'Paraíba' },
                { sigla: 'PR', nome: 'Paraná' },
                { sigla: 'PE', nome: 'Pernambuco' },
                { sigla: 'PI', nome: 'Piauí' },
                { sigla: 'RJ', nome: 'Rio de Janeiro' },
                { sigla: 'RN', nome: 'Rio Grande do Norte' },
                { sigla: 'RS', nome: 'Rio Grande do Sul' },
                { sigla: 'RO', nome: 'Rondônia' },
                { sigla: 'RR', nome: 'Roraima' },
                { sigla: 'SC', nome: 'Santa Catarina' },
                { sigla: 'SP', nome: 'São Paulo' },
                { sigla: 'SE', nome: 'Sergipe' },
                { sigla: 'TO', nome: 'Tocantins' },
            ],
            currentPage: 1,
            previousPage: 0,
            nextPage: 0,
            indexOfLastItem: 0,
            indexOfFirstItem : 0,
            currentItems: 0,
            contactsCountAll: 0,
            inputSearch: '',
        }
    }


    async fetchGetTags() {

        let response = await axios.get('/tags/get_tags.json');
        this.setState({tags: response.data.tags})

    }
    async fetchGetContacts() {
        const { instance_number_phone} = this.props
        const { currentPage} = this.state
        let response = await axios.get('/contacts/get_contacts.json',{
            params:{
                instance_number_phone: instance_number_phone,
                page: currentPage,
                rows: 20
            }
        });
        this.setState({contacts: response.data.contacts})
        this.setState({contactsCountAll: response.data.contact_count})

    }
    async fetchGetTagContacts(){


        let response = await axios.get('/tags/get_tag_contacts.json');

        this.setState({tagContacts: response.data.tag_contacts})
        this.setState({contactsCount: response.data.contacts_count})
    }

    handleFormTagSubmit = async () => {

        const {inputTitle} = this.state

        const formData = new FormData();
        formData.append('title',inputTitle);
        axios.post('/tags', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                console.log('Resposta do servidor:', response.data);
            })
            .catch(error => {
                console.error('Erro ao enviar arquivos:', error);
            });

    }
    handleSelectedTag(id){
        if (id === "pills-icontacts"){
            this.setState({selectedTag: null})
        }else {
            this.setState({selectedTag: id})
        }
    }
    handleDeleteTag(id){
        const formData = new FormData();
        formData.append('id',id);


        axios.delete(`/delete_tag_contacts/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                console.log('Resposta do servidor:', response.data);
                window.location.reload()
            })
            .catch(error => {
                console.error('Erro ao enviar arquivos:', error);
            });
    }


    openMoreEdit(id) {
        let id_tab = "editMore" + id;
        let editMoreInfo = "editMoreInfo" + id;
        console.log("Element ID:", id_tab);

        let elementEditMoreInfo = document.getElementById(editMoreInfo);
        if (elementEditMoreInfo) {
            elementEditMoreInfo.style.display = "none";
        } else {
            console.error("Element not found:", elementEditMoreInfo);
        }

        let element = document.getElementById(id_tab);
        console.log("Element:", element);
        if (element) {
            element.style.display = "block";
        } else {
            console.error("Element not found:", id_tab);
        }
    }
    openMoreSave(idMoreInfo) {
        let moreInfoLabal = "moreInfoLabal"

        let elementSaveMoreInfo = document.getElementById(moreInfoLabal);
        if (elementSaveMoreInfo) {
            elementSaveMoreInfo.style.display = "none";
        } else {
            console.error("Element not found:", elementSaveMoreInfo);
        }

        let element = document.getElementById(idMoreInfo);
        console.log("Element:", element);
        if (element) {
            element.style.display = "block";
        } else {
            console.error("Element not found:", idMoreInfo);
        }

    }
    handleChangeName = (e) => {
        const {  value } = e.target;
        this.setState({name: value})

    };
    handleChangeEmail = (e) => {
        const {  value } = e.target;
        this.setState({email: value})

    };
    handleChangePhone = (e) => {
        const {  value } = e.target;
        this.setState({phone: value})

    };
    handleChangePhoneType = (e) => {
        const {  value } = e.target;
        this.setState({phoneType: value})

    };
    handleChangeTag = (e) => {
        const {  value } = e.target;
        this.setState({tagSelected: value})

    };
    handleChangeInterest = (e) => {
        const {  value } = e.target;
        this.setState({interest: value})

    };
    handleChangeNumProcess = (e) => {
        const {  value } = e.target;
        this.setState({numProcess: value})

    };
    handleInputChangeShareValue = (values) => {
        const { floatValue } = values;
        this.setState({ shareValue: floatValue });
    };
    handleChangeAddress = (e) => {
        const {  value } = e.target;
        this.setState({address: value})

    };
    handleChangeCity = (e) => {
        const {  value } = e.target;
        this.setState({city: value})

    };
    handleChangeState = (e) => {
        const {  value } = e.target;
        this.setState({state: value})

    };
    handleChangeCep= (e) => {
        const {  value } = e.target;
        this.setState({cep: value})

    };
    handleContactIdSelected = (id) => {
        this.setState({contactIdSelected: id})

    };
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({[name]: value });
    };
    handleRemoveContactTag =  (id) => {

        const formData = new FormData();
        formData.append('id',id);


        axios.put('/contacts/remove_contact_for_tag', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                console.log('Resposta do servidor:', response.data);
                window.location.reload()
            })
            .catch(error => {
                console.error('Erro ao enviar arquivos:', error);
            });



    };
    handleRemoveContact =  (id) => {

        const formData = new FormData();
        formData.append('id',id);


        axios.put('/contacts/remove_contact_for_me', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                console.log('Resposta do servidor:', response.data);
                window.location.reload()
            })
            .catch(error => {
                console.error('Erro ao enviar arquivos:', error);
            });



    };
    handleEditSubmit = async (e) => {
        e.preventDefault();
        const {name, email, phone, phoneType, interest, numProcess, address, city, state, cep , contactIdSelected, tagSelected, shareValue} = this.state

        const formData = new FormData();
        formData.append('id',contactIdSelected);
        formData.append('name',name);
        formData.append('email',email);
        formData.append('phone',phone);
        formData.append('phoneType',phoneType);
        formData.append('interest',interest);
        formData.append('numProcess',numProcess);
        formData.append('shareValue',shareValue);
        formData.append('address',address);
        formData.append('city',city);
        formData.append('state',state);
        formData.append('cep',cep);
        formData.append('tagSelected',tagSelected);


        axios.post('/contacts/edit', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                console.log('Resposta do servidor:', response.data);
                window.location.reload()
            })
            .catch(error => {
                console.error('Erro ao enviar arquivos:', error);
            });


        console.log("");
    };
    handleSaveSubmit = async (e) => {
        e.preventDefault();
        const {name, email, phone, phoneType, interest, numProcess, address, city, state, cep, tagSelected } = this.state

        const formData = new FormData();

        formData.append('name',name);
        formData.append('email',email);
        formData.append('phone',phone);
        formData.append('phoneType',phoneType);
        formData.append('interest',interest);
        formData.append('numProcess',numProcess);
        formData.append('address',address);
        formData.append('city',city);
        formData.append('state',state);
        formData.append('cep',cep);
        formData.append('tag',tagSelected);

        axios.post('/contacts', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                console.log('Resposta do servidor:', response.data);
                window.location.reload()
            })
            .catch(error => {
                console.error('Erro ao enviar arquivos:', error);
            });


        console.log("");
    };
    handlePageChange = (pageNumber) =>{

        this.setState({currentPage: pageNumber})
        this.fetchGetContacts()

    }
    handleSendSearch = async () => {

        const { instance_number_phone} = this.props
        const { currentPage, inputSearch,selectedTag} = this.state

        let response = await axios.get('/contacts/get_contacts.json',{
            params:{
                instance_number_phone: instance_number_phone,
                inputSearch: inputSearch,
                type: "inputSearch",
                currentTag: selectedTag,
                page: currentPage,
                rows: 20
            }
        });

        this.setState({contacts: response.data.contacts})
        this.setState({contactsCountAll: response.data.contact_count})


    }
    handleInputClick = () => {
        this.setState({ inputSearch: ''});
        this.fetchGetContacts()
    }
    handleInputChange = (event) => {
        this.setState({ inputSearch: event.target.value });

    }
    handleInputTagChange = (event) => {
        this.setState({ inputTitle: event.target.value });

    }
    formatPhoneNumber = (phone) => {
        const cleaned = phone.replace(/\D/g, ''); // Remove todos os caracteres não numéricos

        // Verifica se o número tem 10 ou 11 dígitos
        if (cleaned.length === 10) {
            const match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
            if (match) {
                return `(${match[1]}) ${match[2]}-${match[3]}`;
            }
        } else if (cleaned.length === 11) {
            const match = cleaned.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/);
            if (match) {
                return `(${match[1]}) ${match[2]} ${match[3]}-${match[4]}`;
            }
        }

        // Retorna o número sem formatação se não corresponder a 10 ou 11 dígitos
        return phone;
    };
    formatCurrencyBRL = (value) => {
        const numericValue = parseFloat(value).toFixed(2); // Garante duas casas decimais
        const [integerPart, decimalPart] = numericValue.split('.'); // Separa a parte inteira da decimal

        // Formata a parte inteira com pontos para separar os milhares
        const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        // Retorna o valor formatado como moeda brasileira
        return `R$ ${formattedIntegerPart},${decimalPart}`;
    };

    componentDidMount() {
        this.fetchGetContacts()
        this.fetchGetTags();
        this.fetchGetTagContacts();

        // Calcula o índice do primeiro e do último item na página atual


    }
    componentWillUnmount() {}
    render() {
        const {user_avatar, user, first_tag,  instance_number_phone, currentCompany, image_avatar} = this.props

        const {tags,tagContacts,contactsCount ,contacts, estadosBrasil , selectedTag, contactsCountAll, inputSearch, inputTitle} = this.state



        return (
            <div className="email-wrap bookmark-wrap">
                    <div className="row">
                        <div className="col-xl-9 col-md-12 box-col-12">
                            <div className="email-right-aside bookmark-tabcontent contacts-tabs">
                                <div className="card email-body radius-left">
                                    <div className="ps-0">
                                        <div className="tab-content">
                                            {!selectedTag  &&
                                                <div className={selectedTag === null ? 'tab-pane fade active show' : 'tab-pane fade'} id={`pills-icontacts`} role="tabpanel"
                                                    aria-labelledby={`pills-icontacts-tab`}>
                                                    <div className="card mb-0">
                                                        <div className="card-header d-flex">
                                                            <h5>Contatos</h5>
                                                            {contacts ?
                                                                <p
                                                                    className="f-14 pull-right mt-0"> {contactsCountAll} Contatos</p>
                                                                :
                                                                <p
                                                                    className="f-14 pull-right mt-0"> 0 Contatos</p>
                                                            }


                                                        </div>
                                                        <div className="card-body p-0">
                                                            <div className="row list-persons" id="addcon">
                                                                <div className="col-xl-4 xl-50 col-md-5">
                                                                    <div style={{marginBottom: 10}}></div>
                                                                    <div style={{padding: 10}}>
                                                                        <div className="input-group search-area">
                                                                        <span onClick={this.handleSendSearch}
                                                                              className="input-group-text"><a
                                                                            href="#"><i
                                                                            className="bx bx-search"></i></a></span>
                                                                            <input onClick={this.handleInputClick}
                                                                                   type="text"
                                                                                   onChange={this.handleInputChange}
                                                                                   value={inputSearch}
                                                                                   className="form-control"
                                                                                   placeholder="Buscar"></input>

                                                                        </div>
                                                                    </div>


                                                                    <div style={{marginBottom: 10}}></div>
                                                                    <div className="nav flex-column nav-pills"
                                                                         id="v-pills-tab" role="tablist"
                                                                         aria-orientation="vertical">
                                                                        {/*  Listagem de contatos*/}

                                                                        {!contacts && <>Sem resultado</>}
                                                                        {contacts && Object.values(contacts).map(contact => (

                                                                            <a className={`contact-tab-${contact.id} nav-link`}
                                                                               href={`#v-pills-user-${contact.id}`}
                                                                               aria-controls={`v-pills-user-${contact.id}`}
                                                                               data-bs-original-title=""
                                                                               data-bs-toggle="pill"
                                                                               id={`v-pills-user-tab-${contact.id}`}
                                                                               role="tab">
                                                                                <div className="media">
                                                                                    <ImageWithFallback
                                                                                        classImage={"img-50 img-fluid m-r-20 rounded-circle update_img_0"}
                                                                                        src={contact.avatar}
                                                                                        fallback={image_avatar}
                                                                                        alt="contact"
                                                                                    />

                                                                                    <div >
                                                                                        <a className="font-w400 first_name_0">
                                                                                            {contact.name}
                                                                                        </a>
                                                                                        <p className="email_add_0">{contact.phone}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </a>

                                                                        ))}


                                                                    </div>
                                                                    {/*contactsCountAll > 0 && <div style={{
                                                                        alignItems: "center",
                                                                        justifyContent: "center"
                                                                    }}
                                                                                                  className="align-items-center justify-content-center">
                                                                        <Pagination contactsCountAll={contactsCountAll}
                                                                                    onPageChange={this.handlePageChange}/>
                                                                    </div>*/}

                                                                </div>
                                                                <div className="col-xl-8 xl-50 col-md-7">
                                                                    {/*  Detalhes dd contato*/}

                                                                    <div className="tab-content"
                                                                         id={`v-pills-tabContent`}>
                                                                        {!contacts && <>Sem resultado</>}
                                                                    {contacts && Object.values(contacts).map(contact => (

                                                                            <div
                                                                                className={`tab-pane contact-tab-${contact.id} tab-content-child fade`}
                                                                                id={`v-pills-user-${contact.id}`}
                                                                                role="tabpanel"
                                                                                aria-labelledby={`v-pills-user-tab-${contact.id}`}>
                                                                                <div className="profile-mail">
                                                                                    <div className="media">
                                                                                        <ImageWithFallback
                                                                                            classImage={"img-fluid m-r-20 rounded-circle update_img_0"}
                                                                                            src={contact.avatar}
                                                                                            fallback={image_avatar}
                                                                                            alt="contact"
                                                                                        />
                                                                                        <div className=" mt-0">
                                                                                            <h5><a href={`/messagings.${contact.id}`} className="first_name_0">{contact.name}</a></h5>
                                                                                            <p className="email_add_0">{contact.phone}</p>

                                                                                            <div
                                                                                                className="media-body ">
                                                                                                <ul className="d-flex">
                                                                                                    <li><a
                                                                                                        href="#"
                                                                                                        data-bs-toggle="modal"
                                                                                                        data-bs-target={`#editModal-${contact.id}`}
                                                                                                        data-bs-original-title=""
                                                                                                        title="">Editar</a>
                                                                                                    </li>
                                                                                                    <li><a
                                                                                                        href="#"
                                                                                                        data-bs-toggle="modal"
                                                                                                        data-bs-target={`#removeModal-${contact.id}`}
                                                                                                        data-bs-original-title=""
                                                                                                        title="">Deletar</a>
                                                                                                    </li>
                                                                                                    <li><a
                                                                                                        href={`/messagings.${contact.id}`}
                                                                                                        title="">Chat <i
                                                                                                        className='bx bxl-whatsapp'></i></a>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="email-general">
                                                                                        <h6 className="mb-3">Geral</h6>
                                                                                        <ul>
                                                                                            <li>Nome<span
                                                                                                className="font-primary first_name_0">{contact.name}</span>
                                                                                            </li>
                                                                                            <li>Cliente<span
                                                                                                className="font-primary personality_0">{contact.numProcess === null ? "Não" : "Sim"} </span>
                                                                                            </li>
                                                                                            <li>Cidade<span
                                                                                                className="font-primary city_0">{contact.city}</span>
                                                                                            </li>
                                                                                            <li>E-mail<span
                                                                                                className="font-primary email_add_0">{contact.city} </span>
                                                                                            </li>


                                                                                            {currentCompany.type_company === "advocacia" &&
                                                                                                <li>Interesse<span
                                                                                                    className="font-primary interest_0">{contact.insterest}</span>
                                                                                                </li>
                                                                                            }
                                                                                            {currentCompany.type_company === "advocacia" &&
                                                                                                <li>Processual<span
                                                                                                    className="font-primary url_add_0">{contact.numProcess}</span>
                                                                                                </li>
                                                                                            }
                                                                                            {currentCompany.type_company === "advocacia" &&
                                                                                                <li>Valor da Ação<span
                                                                                                    className="font-primary url_add_0">{this.formatCurrencyBRL(contact.shareValue || "0")}</span>
                                                                                                </li>
                                                                                            }

                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )) }


                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }


                                            { Object.values(contacts).map(contact => (

                                                <React.Fragment>

                                                    <div
                                                        className="modal custom-modal fade"
                                                        id={`removeModal-${contact.id}`}
                                                        tabIndex="-1"
                                                        style={{display: "none"}}
                                                        aria-hidden="true">
                                                        <div className="modal-dialog modal-dialog-centered"
                                                             role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title"> Deletar</h5>
                                                                    <button
                                                                        className="btn-close"
                                                                        type="button"
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"
                                                                        data-bs-original-title=""
                                                                        title=""></button>
                                                                </div>

                                                                <div className="modal-body list-persons">
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-center">
                                                                        <p>Deseja deletar - {contact.name} ?</p>
                                                                    </div>

                                                                    <div className="mt-10 ps-0"
                                                                         id={`edit-${contact.id}`}>
                                                                        <div
                                                                            className="d-flex align-items-center justify-content-center">
                                                                            <button
                                                                                style={{marginRight: 20}}
                                                                                className="btn btn-secondary "
                                                                                type="submit"
                                                                                onClick={() => this.handleRemoveContact(contact.id)}
                                                                                data-bs-original-title=""
                                                                                title="">Deletar
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-primary"
                                                                                type="button"
                                                                                data-bs-dismiss="modal"
                                                                                data-bs-original-title=""
                                                                                title="">Cancelar
                                                                            </button>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="modal custom-modal fade"
                                                        id={`removeContactTagModal-${contact.id}`}
                                                        tabIndex="-1"
                                                        style={{display: "none"}}
                                                        aria-hidden="true">
                                                        <div className="modal-dialog modal-dialog-centered"
                                                             role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title"> Remover do Agrupamento</h5>
                                                                    <button
                                                                        className="btn-close"
                                                                        type="button"
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"
                                                                        data-bs-original-title=""
                                                                        title=""></button>
                                                                </div>

                                                                <div className="modal-body list-persons">
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-center">
                                                                        <p>Deseja remover - {contact.name} ?</p>
                                                                    </div>

                                                                    <div className="mt-10 ps-0"
                                                                         id={`edit-${contact.id}`}>
                                                                        <div
                                                                            className="d-flex align-items-center justify-content-center">
                                                                            <button
                                                                                style={{marginRight: 20}}
                                                                                className="btn btn-secondary "
                                                                                type="submit"
                                                                                onClick={() => this.handleRemoveContactTag(contact.id)}
                                                                                data-bs-original-title=""
                                                                                title="">Deletar
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-primary"
                                                                                type="button"
                                                                                data-bs-dismiss="modal"
                                                                                data-bs-original-title=""
                                                                                title="">Cancelar
                                                                            </button>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>




                                                    <div
                                                        className="modal  fade"
                                                        id={`editModal-${contact.id}`}
                                                        tabIndex="-1"
                                                        style={{display: "none"}}
                                                        aria-hidden="true">
                                                        <div
                                                            className="modal-dialog modal-dialog-centered"
                                                            role="document">
                                                            <div
                                                                className="modal-content">
                                                                <div
                                                                    className="modal-header">
                                                                    <h5 className="modal-title"> Editar
                                                                        - {contact.name}</h5>
                                                                    <button
                                                                        className="btn-close"
                                                                        type="button"
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"
                                                                        data-bs-original-title=""
                                                                        title=""></button>
                                                                </div>
                                                                <div
                                                                    className="modal-body list-persons">
                                                                    <div
                                                                        className=" ps-0"
                                                                        id={`edit-${contact.id}`}>
                                                                        <form className="form-bookmark needs-validation"
                                                                              id="bookmark-form2" noValidate=""
                                                                              onSubmit={this.handleEditSubmit}>
                                                                            <div
                                                                                className="row g-2">
                                                                                <div
                                                                                    className="mt-0 mb-3 col-md-12">

                                                                                    <label>Nome</label>
                                                                                    <div
                                                                                        className="row">
                                                                                        <div
                                                                                            className="col-sm-12">
                                                                                            <input
                                                                                                className="form-control"
                                                                                                id="name"
                                                                                                name="name"
                                                                                                type="text"

                                                                                                placeholder={contact.name}
                                                                                                onChange={this.handleChangeName}
                                                                                                data-bs-original-title=""
                                                                                                title="Por favor, digite o nome"/>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="mt-0 mb-3 col-md-12">
                                                                                    <label>Email </label>
                                                                                    <input
                                                                                        className="form-control"
                                                                                        id="email"
                                                                                        name="email"
                                                                                        type="email"
                                                                                        placeholder={contact.email}
                                                                                        onChange={this.handleChangeEmail}
                                                                                        autoComplete="off"
                                                                                        data-bs-original-title=""
                                                                                        title="Por favor, digite o endereço de e-mail."/>
                                                                                </div>
                                                                                <div
                                                                                    className="mt-0 mb-20 col-md-12">
                                                                                    <label>Telefone</label>
                                                                                    <div
                                                                                        className="row">
                                                                                        <div
                                                                                            className="col-sm-6">
                                                                                            <InputMask
                                                                                                mask="+55 (99) 9 9999-9999"
                                                                                                maskChar="_"
                                                                                                className="form-control"
                                                                                                id="num_phone"
                                                                                                name="num_phone"
                                                                                                type="phone"
                                                                                                placeholder={contact.phone}
                                                                                                onChange={this.handleChangePhone}
                                                                                                title="Por favor, digite o número de telefone."
                                                                                                autoComplete="off"
                                                                                                data-bs-original-title=""
                                                                                            />
                                                                                        </div>
                                                                                        <div
                                                                                            className="mb-20 col-sm-6">
                                                                                            <select
                                                                                                className="form-control">
                                                                                                onChange={this.handleChangePhoneType}
                                                                                                <option
                                                                                                    value="mobile">Celular
                                                                                                </option>
                                                                                                <option
                                                                                                    value="home">Residência
                                                                                                </option>
                                                                                                <option
                                                                                                    value="others">outros
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                id={`editMore${contact.id}`}
                                                                                className="row g-2 more-data"
                                                                                style={{display: "none"}}>

                                                                                <div className="mb-20 col-sm-12">
                                                                                    <label>Agrupamento</label>
                                                                                    <select className="form-control"
                                                                                            onChange={this.handleChangeTag}>
                                                                                        <option defaultValue>selecione
                                                                                            qual Agrupamento
                                                                                            deseja associar
                                                                                        </option>

                                                                                        {Object.values(tags).map(tag => (

                                                                                            <option
                                                                                                key={tag.id}
                                                                                                value={tag.id}>
                                                                                                {tag.title}
                                                                                            </option>
                                                                                        ))}

                                                                                    </select>
                                                                                </div>
                                                                                {currentCompany.type_company === "advocacia" &&
                                                                                    <div className="mb-3 col-md-12">
                                                                                        <label>Interresse</label>
                                                                                        <select
                                                                                            id="opt_interest"
                                                                                            name="opt_interest"
                                                                                            title="Por favor, selecione o Interresse."

                                                                                            className="form-control">
                                                                                            onChange={this.handleChangeInterest}
                                                                                            <option
                                                                                                selected>selecione
                                                                                                o
                                                                                                Interresse
                                                                                            </option>
                                                                                            <option
                                                                                                value="labor_lawyer">Advogado
                                                                                                Trabalhista
                                                                                            </option>
                                                                                            <option
                                                                                                value="civil_lawyer">Advogado
                                                                                                Civil
                                                                                            </option>
                                                                                            <option
                                                                                                value="criminal_lawyer">Advogado
                                                                                                Criminal
                                                                                            </option>
                                                                                            <option
                                                                                                value="family_lawyer">Advogado
                                                                                                de
                                                                                                Família
                                                                                            </option>
                                                                                            <option
                                                                                                value="tax_lawyer">Advogado
                                                                                                Tributarista
                                                                                            </option>
                                                                                            <option
                                                                                                value="environmental_lawyer">Advogado
                                                                                                Ambiental
                                                                                            </option>
                                                                                            <option
                                                                                                value="business_lawyer">Advogado
                                                                                                Empresarial
                                                                                            </option>
                                                                                            <option
                                                                                                value="real_estate_lawyer">Advogado
                                                                                                Imobiliário
                                                                                            </option>
                                                                                            <option
                                                                                                value="social_security_lawyer">Advogado
                                                                                                Previdenciário
                                                                                            </option>
                                                                                            <option
                                                                                                value="consumer_lawyer">Advogado
                                                                                                do
                                                                                                Consumidor
                                                                                            </option>
                                                                                            <option
                                                                                                value="international_lawyer">Advogado
                                                                                                Internacional
                                                                                            </option>
                                                                                            <option
                                                                                                value="intellectual_property_lawyer">Advogado
                                                                                                de
                                                                                                Propriedade
                                                                                                Intelectual
                                                                                            </option>
                                                                                            <option
                                                                                                value="military_lawyer">Advogado
                                                                                                Militar
                                                                                            </option>
                                                                                            <option
                                                                                                value="administrative_lawyer">Advogado
                                                                                                Administrativo
                                                                                            </option>
                                                                                            <option
                                                                                                value="human_rights_lawyer">Advogado
                                                                                                de
                                                                                                Direitos
                                                                                                Humanos
                                                                                            </option>

                                                                                        </select>
                                                                                    </div>
                                                                                }
                                                                                {currentCompany.type_company === "advocacia" &&
                                                                                    <div className="mb-30 col-md-12">
                                                                                        <label>Número
                                                                                            do
                                                                                            Processo</label>
                                                                                        <div
                                                                                            className="row">
                                                                                            <div
                                                                                                className="col-sm-12">
                                                                                                <InputMask

                                                                                                    onChange={this.handleChangeNumProcess}
                                                                                                    mask="9999999-99.9999.9.99.9999"
                                                                                                    maskChar="_"
                                                                                                    className="form-control"
                                                                                                    id="num_Process"
                                                                                                    name="num_Process"
                                                                                                    type="text"
                                                                                                    placeholder="Por favor, digite o número do Processo"
                                                                                                    title="Por favor, digite o número do Processo."
                                                                                                    autoComplete="off"
                                                                                                    data-bs-original-title=""
                                                                                                />
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                {currentCompany.type_company === "advocacia" &&
                                                                                    <div className="mb-30 col-md-12">
                                                                                        <label>Valor da Ação</label>
                                                                                        <div
                                                                                            className="row">
                                                                                            <div
                                                                                                className="col-sm-12">
                                                                                                <NumericFormat
                                                                                                    value={contact.shareValue}
                                                                                                    thousandSeparator="."
                                                                                                    decimalSeparator=","
                                                                                                    prefix="R$ "
                                                                                                    decimalScale={2}
                                                                                                    fixedDecimalScale={true}
                                                                                                    allowNegative={false}
                                                                                                    onValueChange={this.handleInputChangeShareValue}
                                                                                                    className="form-control"
                                                                                                    id="share_value"
                                                                                                    name="share_value"
                                                                                                    placeholder="Por favor, digite o valor"
                                                                                                />
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                }

                                                                                <div
                                                                                    className="mb-3 col-md-12">
                                                                                    <label>Endereço</label>

                                                                                    <div
                                                                                        className="row">
                                                                                        <div
                                                                                            className=" mb-10 col-12">
                                                                                            <div>
                                                                                                <input
                                                                                                    className="form-control"
                                                                                                    type="text"
                                                                                                    id="address"
                                                                                                    name="address"
                                                                                                    onChange={this.handleChangeAddress}
                                                                                                    placeholder="Endereço"
                                                                                                    data-bs-original-title=""
                                                                                                    title=""/>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div
                                                                                            className=" mb-10 col-sm-6">
                                                                                            <div>
                                                                                                <input
                                                                                                    className="form-control"
                                                                                                    id="city"
                                                                                                    name="city"
                                                                                                    type="text"
                                                                                                    onChange={this.handleChangeCity}
                                                                                                    placeholder="Cidade"
                                                                                                    data-bs-original-title=""
                                                                                                    title=""/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            className=" mb-10 col-sm-6">

                                                                                            <select
                                                                                                id="state"
                                                                                                name="state"
                                                                                                onChange={this.handleChangeState}
                                                                                                className="form-control">
                                                                                                <option
                                                                                                    selected>Estado
                                                                                                </option>
                                                                                                {estadosBrasil.map((estado) => (

                                                                                                    <option
                                                                                                        key={estado.sigla}
                                                                                                        value={estado.sigla}>
                                                                                                        {estado.sigla}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </select>


                                                                                        </div>


                                                                                        <div
                                                                                            className=" mb-10 col-sm-6">
                                                                                            <div>
                                                                                                <InputMask
                                                                                                    mask="99999-999"
                                                                                                    maskChar="_"
                                                                                                    className="form-control"
                                                                                                    id="cep"
                                                                                                    name="cep"
                                                                                                    type="text"
                                                                                                    onChange={this.handleChangeCep}
                                                                                                    placeholder="Digite o CEP"
                                                                                                    data-bs-original-title=""
                                                                                                    title=""/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <a className="ps-0 edit-information"
                                                                               href="#"
                                                                               onClick={() => this.openMoreEdit(contact.id)}
                                                                               data-bs-original-title=""
                                                                               id={`editMoreInfo${contact.id}`}
                                                                               title="">Editar
                                                                                mais
                                                                                informações</a>

                                                                            <div
                                                                                className="d-flex align-items-center justify-content-center">
                                                                                <button
                                                                                    style={{marginRight: 20}}
                                                                                    className="btn btn-secondary update-contact"
                                                                                    type="submit"
                                                                                    onClick={() => this.handleContactIdSelected(contact.id)}
                                                                                    data-bs-original-title=""
                                                                                    title="">Salvar
                                                                                </button>
                                                                                <button
                                                                                    className="btn btn-primary"
                                                                                    type="button"
                                                                                    data-bs-dismiss="modal"
                                                                                    data-bs-original-title=""
                                                                                    title="">Cancelar
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </React.Fragment>


                                            ))}

                                            {selectedTag && tags && tags.map((tag) => (<>
                                                    <div
                                                        className={selectedTag === tag.id ? 'tab-pane fade active show' : 'tab-pane fade'}
                                                        id={`pills-${tag.id}`} role="tabpanel"
                                                        aria-labelledby={`pills-${tag.id}-tab`}>
                                                        <div className="card mb-0">
                                                            <div className="card-header d-flex">
                                                                {tag.title}
                                                                <p
                                                                    className="f-14 pull-right mt-0"> {contactsCount[tag.id]} Contatos</p>
                                                            </div>
                                                            <div className="card-body p-0">
                                                                <div className="row list-persons" id="addcon">
                                                                    <div className="col-xl-4 xl-50 col-md-5">
                                                                        <div style={{marginBottom: 10}}></div>

                                                                        <div style={{padding: 10}}>
                                                                            <div className="input-group search-area">
                                                                                <span onClick={this.handleSendSearch}
                                                                                      className="input-group-text">
                                                                                    <a
                                                                                        href="#"><i
                                                                                        className="bx bx-search"></i>
                                                                                    </a>
                                                                                </span>
                                                                                <input onClick={this.handleInputClick}
                                                                                       type="text"
                                                                                       onChange={this.handleInputChange}
                                                                                       value={inputSearch}
                                                                                       className="form-control"
                                                                                       placeholder="Buscar"></input>
                                                                            </div>
                                                                        </div>


                                                                        <div style={{marginBottom: 5}}></div>
                                                                        <div className="nav flex-column nav-pills"
                                                                             id="v-pills-tab" role="tablist"
                                                                             aria-orientation="vertical">
                                                                            {/*  Listagem de contatos*/}
                                                                            {!tagContacts && <>Sem resultado</>}
                                                                            {Object.values(tagContacts[tag.id]).map(contact => (

                                                                                <a
                                                                                    className={`contact-tab-${tag.id}  nav-link`}
                                                                                    href={`#v-pills-user-${contact.id}`}
                                                                                    aria-controls={`v-pills-user-${contact.id}`}
                                                                                    data-bs-original-title=""
                                                                                    data-bs-toggle="pill"
                                                                                    id={`v-pills-user-tab-${contact.id}`}
                                                                                    role="tab">
                                                                                    <div className="media">
                                                                                        <ImageWithFallback
                                                                                            classImage={"img-50 img-fluid m-r-20 rounded-circle update_img_0"}
                                                                                            src={contact.avatar}
                                                                                            fallback={image_avatar}
                                                                                            alt="contact"
                                                                                        />
                                                                                        <div className="">
                                                                                            <h6 className="font-w400 first_name_0">
                                                                                                {contact.name}
                                                                                            </h6>
                                                                                            <p className="email_add_0">{contact.phone}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>

                                                                            ))}


                                                                        </div>
                                                                        {/*Object.values(tagContacts[tag.id]).length > 0 &&
                                                                        <div style={{
                                                                            alignItems: "center",
                                                                            justifyContent: "center"
                                                                        }}
                                                                             className="align-items-center justify-content-center">
                                                                            <Pagination
                                                                                contactsCountAll={Object.values(tagContacts[tag.id]).length}
                                                                                onPageChange={this.handlePageChange}/>
                                                                        </div>*/}
                                                                    </div>
                                                                    <div className="col-xl-8 xl-50 col-md-7">
                                                                        {/*  Detalhes dd contato*/}

                                                                        <div className="tab-content"
                                                                             id={`v-pills-tabContent-`}>
                                                                            {tagContacts[tag.id] && Object.values(tagContacts[tag.id]).map(contact => (

                                                                                <div
                                                                                    className={`tab-pane contact-tab-${tag.id} tab-content-child fade`}
                                                                                    id={`v-pills-user-${contact.id}`}
                                                                                    role="tabpanel"
                                                                                    aria-labelledby={`v-pills-user-tab-${contact.id}`}>
                                                                                    <div className="profile-mail">
                                                                                        <div className="media">
                                                                                            <ImageWithFallback
                                                                                                classImage={"img-fluid m-r-20 rounded-circle update_img_0"}
                                                                                                src={contact.avatar}
                                                                                                fallback={image_avatar}
                                                                                                alt="contact"
                                                                                            />
                                                                                            <div className=" mt-0">
                                                                                                <h5><span
                                                                                                    className="first_name_0">{contact.name}</span>
                                                                                                </h5>
                                                                                                <p className="email_add_0">{contact.phone}</p>

                                                                                                <div
                                                                                                    className="media-body ">
                                                                                                    <ul className="d-flex">
                                                                                                        <li><a
                                                                                                            href="#"
                                                                                                            data-bs-toggle="modal"
                                                                                                            data-bs-target={`#editModal-${contact.id}`}
                                                                                                            data-bs-original-title=""
                                                                                                            title="">Editar</a>
                                                                                                        </li>
                                                                                                        <li><a
                                                                                                            href="#"
                                                                                                            data-bs-toggle="modal"
                                                                                                            data-bs-target={`#removeContactTagModal-${contact.id}`}
                                                                                                            data-bs-original-title=""
                                                                                                            title="">Remover
                                                                                                            Agrupamento</a>
                                                                                                        </li>
                                                                                                        <li><a
                                                                                                            href="#"
                                                                                                            data-bs-toggle="modal"
                                                                                                            data-bs-target={`#removeModal-${contact.id}`}
                                                                                                            data-bs-original-title=""
                                                                                                            title="">Deletar</a>
                                                                                                        </li>

                                                                                                    </ul>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            className="email-general">
                                                                                            <h6 className="mb-3">Geral</h6>
                                                                                            <ul>
                                                                                                <li>Nome<span
                                                                                                    className="font-primary first_name_0">{contact.name}</span>
                                                                                                </li>
                                                                                                <li>Cliente<span
                                                                                                    className="font-primary personality_0">{contact.numProcess === null ? "Não" : "Sim"} </span>
                                                                                                </li>
                                                                                                <li>Cidade<span
                                                                                                    className="font-primary city_0">{contact.city}</span>
                                                                                                </li>
                                                                                                <li>E-mail<span
                                                                                                    className="font-primary email_add_0">{contact.city} </span>
                                                                                                </li>

                                                                                                {currentCompany.type_company === "advocacia" &&
                                                                                                    <li>Interesse<span
                                                                                                        className="font-primary interest_0">{contact.insterest}</span>
                                                                                                    </li>
                                                                                                }
                                                                                                {currentCompany.type_company === "advocacia" &&
                                                                                                    <li>Processual<span
                                                                                                        className="font-primary url_add_0">{contact.numProcess}</span>
                                                                                                    </li>
                                                                                                }
                                                                                                {currentCompany.type_company === "advocacia" &&
                                                                                                    <li>Valor da Ação<span
                                                                                                        className="font-primary url_add_0">{this.formatCurrencyBRL(contact.shareValue || 0)}</span>
                                                                                                    </li>
                                                                                                }
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}

                                                                        </div>

                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {tagContacts[tag.id] && Object.values(tagContacts[tag.id]).map(contact => (
                                                        <React.Fragment>
                                                            <div
                                                                className="modal fade "
                                                                id={`editModal-${contact.id}`}
                                                                tabIndex="-1"
                                                                style={{display: "none"}}
                                                                aria-hidden="true">
                                                                <div
                                                                    className="modal-dialog modal-dialog-centered"
                                                                    role="document">
                                                                    <div
                                                                        className="modal-content">
                                                                        <div
                                                                            className="modal-header">
                                                                            <h5 className="modal-title"> Editar
                                                                                - {contact.name}</h5>
                                                                            <button
                                                                                className="btn-close"
                                                                                type="button"
                                                                                data-bs-dismiss="modal"
                                                                                aria-label="Close"
                                                                                data-bs-original-title=""
                                                                                title=""></button>
                                                                        </div>
                                                                        <div
                                                                            className="modal-body list-persons">
                                                                            <div
                                                                                className=" ps-0"
                                                                                id={`edit-${contact.id}`}>
                                                                                <form
                                                                                    className="form-bookmark needs-validation"
                                                                                    id="bookmark-form1" noValidate=""
                                                                                    onSubmit={this.handleEditSubmit}>
                                                                                    <div
                                                                                        className="row g-2">
                                                                                        <div
                                                                                            className="mt-0 mb-3 col-md-12">

                                                                                            <label>Nome</label>
                                                                                            <div
                                                                                                className="row">
                                                                                                <div
                                                                                                    className="col-sm-12">
                                                                                                    <input
                                                                                                        className="form-control"
                                                                                                        id="name"
                                                                                                        name="name"
                                                                                                        type="text"

                                                                                                        placeholder={contact.name}
                                                                                                        onChange={this.handleChangeName}
                                                                                                        data-bs-original-title=""
                                                                                                        title="Por favor, digite o nome"/>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            className="mt-0 mb-3 col-md-12">
                                                                                            <label>Email </label>
                                                                                            <input
                                                                                                className="form-control"
                                                                                                id="email"
                                                                                                name="email"
                                                                                                type="email"
                                                                                                placeholder={contact.email}
                                                                                                onChange={this.handleChangeEmail}
                                                                                                autoComplete="off"
                                                                                                data-bs-original-title=""
                                                                                                title="Por favor, digite o endereço de e-mail."/>
                                                                                        </div>
                                                                                        <div
                                                                                            className="mt-0 mb-20 col-md-12">
                                                                                            <label>Phone</label>
                                                                                            <div
                                                                                                className="row">
                                                                                                <div
                                                                                                    className="col-sm-6">
                                                                                                    <InputMask
                                                                                                        mask="+55 (99) 9 9999-9999"
                                                                                                        maskChar="_"
                                                                                                        className="form-control"
                                                                                                        id="num_phone"
                                                                                                        name="num_phone"
                                                                                                        type="phone"
                                                                                                        placeholder={contact.phone}
                                                                                                        onChange={this.handleChangePhone}
                                                                                                        title="Por favor, digite o número de telefone."
                                                                                                        autoComplete="off"
                                                                                                        data-bs-original-title=""
                                                                                                    />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="mb-20 col-sm-6">
                                                                                                    <select
                                                                                                        className="form-control">
                                                                                                        onChange={this.handleChangePhoneType}
                                                                                                        <option
                                                                                                            value="mobile">Celular
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="home">Residência
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="others">outros
                                                                                                        </option>
                                                                                                    </select>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        id={`editMore${contact.id}`}
                                                                                        className="row g-2 more-data"
                                                                                        style={{display: "none"}}>

                                                                                        <div
                                                                                            className="mb-20 col-sm-12">
                                                                                            <label>Agrupamento</label>
                                                                                            <select
                                                                                                className="form-control"
                                                                                                onChange={this.handleChangeTag}>
                                                                                                <option
                                                                                                    defaultValue>selecione
                                                                                                    qual Agrupamento
                                                                                                    deseja
                                                                                                    associar
                                                                                                </option>

                                                                                                {Object.values(tags).map(tag => (

                                                                                                    <option
                                                                                                        key={tag.id}
                                                                                                        value={tag.id}>
                                                                                                        {tag.title}
                                                                                                    </option>
                                                                                                ))}

                                                                                            </select>
                                                                                        </div>
                                                                                        {currentCompany.type_company === "advocacia" &&
                                                                                            <div
                                                                                                className="mb-3 col-md-12">
                                                                                                <label>Interresse</label>
                                                                                                <select
                                                                                                    id="opt_interest"
                                                                                                    name="opt_interest"
                                                                                                    title="Por favor, selecione o Interresse."

                                                                                                    className="form-control">
                                                                                                    onChange={this.handleChangeInterest}
                                                                                                    <option
                                                                                                        selected>selecione
                                                                                                        o
                                                                                                        Interresse
                                                                                                    </option>
                                                                                                    <option
                                                                                                        value="labor_lawyer">Advogado
                                                                                                        Trabalhista
                                                                                                    </option>
                                                                                                    <option
                                                                                                        value="civil_lawyer">Advogado
                                                                                                        Civil
                                                                                                    </option>
                                                                                                    <option
                                                                                                        value="criminal_lawyer">Advogado
                                                                                                        Criminal
                                                                                                    </option>
                                                                                                    <option
                                                                                                        value="family_lawyer">Advogado
                                                                                                        de
                                                                                                        Família
                                                                                                    </option>
                                                                                                    <option
                                                                                                        value="tax_lawyer">Advogado
                                                                                                        Tributarista
                                                                                                    </option>
                                                                                                    <option
                                                                                                        value="environmental_lawyer">Advogado
                                                                                                        Ambiental
                                                                                                    </option>
                                                                                                    <option
                                                                                                        value="business_lawyer">Advogado
                                                                                                        Empresarial
                                                                                                    </option>
                                                                                                    <option
                                                                                                        value="real_estate_lawyer">Advogado
                                                                                                        Imobiliário
                                                                                                    </option>
                                                                                                    <option
                                                                                                        value="social_security_lawyer">Advogado
                                                                                                        Previdenciário
                                                                                                    </option>
                                                                                                    <option
                                                                                                        value="consumer_lawyer">Advogado
                                                                                                        do
                                                                                                        Consumidor
                                                                                                    </option>
                                                                                                    <option
                                                                                                        value="international_lawyer">Advogado
                                                                                                        Internacional
                                                                                                    </option>
                                                                                                    <option
                                                                                                        value="intellectual_property_lawyer">Advogado
                                                                                                        de
                                                                                                        Propriedade
                                                                                                        Intelectual
                                                                                                    </option>
                                                                                                    <option
                                                                                                        value="military_lawyer">Advogado
                                                                                                        Militar
                                                                                                    </option>
                                                                                                    <option
                                                                                                        value="administrative_lawyer">Advogado
                                                                                                        Administrativo
                                                                                                    </option>
                                                                                                    <option
                                                                                                        value="human_rights_lawyer">Advogado
                                                                                                        de
                                                                                                        Direitos
                                                                                                        Humanos
                                                                                                    </option>

                                                                                                </select>
                                                                                            </div>
                                                                                        }

                                                                                        {currentCompany.type_company === "advocacia" &&
                                                                                            <div
                                                                                                className="mb-30 col-md-12">
                                                                                                <label>Número
                                                                                                    do
                                                                                                    Processo</label>
                                                                                                <div
                                                                                                    className="row">
                                                                                                    <div
                                                                                                        className="col-sm-12">
                                                                                                        <InputMask

                                                                                                            onChange={this.handleChangeNumProcess}
                                                                                                            mask="9999999-99.9999.9.99.9999"
                                                                                                            maskChar="_"
                                                                                                            className="form-control"
                                                                                                            id="num_Process"
                                                                                                            name="num_Process"
                                                                                                            type="text"
                                                                                                            placeholder="Por favor, digite o número do Processo"
                                                                                                            title="Por favor, digite o número do Processo."
                                                                                                            autoComplete="off"
                                                                                                            data-bs-original-title=""
                                                                                                        />
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        }


                                                                                        <div
                                                                                            className="mb-3 col-md-12">
                                                                                            <label>Endereço</label>

                                                                                            <div
                                                                                                className="row">
                                                                                                <div
                                                                                                    className=" mb-10 col-12">
                                                                                                    <div>
                                                                                                        <input
                                                                                                            className="form-control"
                                                                                                            type="text"
                                                                                                            id="address"
                                                                                                            name="address"
                                                                                                            onChange={this.handleChangeAddress}
                                                                                                            placeholder="Endereço"
                                                                                                            data-bs-original-title=""
                                                                                                            title=""/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div
                                                                                                    className=" mb-10 col-sm-6">
                                                                                                    <div>
                                                                                                        <input
                                                                                                            className="form-control"
                                                                                                            id="city"
                                                                                                            name="city"
                                                                                                            type="text"
                                                                                                            onChange={this.handleChangeCity}
                                                                                                            placeholder="Cidade"
                                                                                                            data-bs-original-title=""
                                                                                                            title=""/>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className=" mb-10 col-sm-6">

                                                                                                    <select
                                                                                                        id="state"
                                                                                                        name="state"
                                                                                                        onChange={this.handleChangeState}
                                                                                                        className="form-control">
                                                                                                        <option
                                                                                                            selected>Estado
                                                                                                        </option>
                                                                                                        {estadosBrasil.map((estado) => (

                                                                                                            <option
                                                                                                                key={estado.sigla}
                                                                                                                value={estado.sigla}>
                                                                                                                {estado.sigla}
                                                                                                            </option>
                                                                                                        ))}
                                                                                                    </select>


                                                                                                </div>


                                                                                                <div
                                                                                                    className=" mb-10 col-sm-6">
                                                                                                    <div>
                                                                                                        <InputMask
                                                                                                            mask="99999-999"
                                                                                                            maskChar="_"
                                                                                                            className="form-control"
                                                                                                            id="cep"
                                                                                                            name="cep"
                                                                                                            type="text"
                                                                                                            onChange={this.handleChangeCep}
                                                                                                            placeholder="Digite o CEP"
                                                                                                            data-bs-original-title=""
                                                                                                            title=""/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <a className="ps-0 edit-information"
                                                                                       href="#"
                                                                                       onClick={() => this.openMoreEdit(contact.id)}
                                                                                       data-bs-original-title=""
                                                                                       id={`editMoreInfo${contact.id}`}
                                                                                       title="">Editar
                                                                                        mais
                                                                                        informações</a>

                                                                                    <div
                                                                                        className="d-flex align-items-center justify-content-center">
                                                                                        <button
                                                                                            style={{marginRight: 20}}
                                                                                            className="btn btn-secondary update-contact"
                                                                                            type="submit"
                                                                                            onClick={() => this.handleContactIdSelected(contact.id)}
                                                                                            data-bs-original-title=""
                                                                                            title="">Salvar
                                                                                        </button>
                                                                                        <button
                                                                                            className="btn btn-primary"
                                                                                            type="button"
                                                                                            data-bs-dismiss="modal"
                                                                                            data-bs-original-title=""
                                                                                            title="">Cancelar
                                                                                        </button>
                                                                                    </div>
                                                                                </form>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="modal custom-modal fade"
                                                                id={`removeModal-${contact.id}`}
                                                                tabIndex="-1"
                                                                style={{display: "none"}}
                                                                aria-hidden="true">
                                                                <div
                                                                    className="modal-dialog modal-dialog-centered"
                                                                    role="document">
                                                                    <div
                                                                        className="modal-content">
                                                                        <div
                                                                            className="modal-header">
                                                                            <h5 className="modal-title"> Remove
                                                                                - {contact.name}</h5>
                                                                            <button
                                                                                className="btn-close"
                                                                                type="button"
                                                                                data-bs-dismiss="modal"
                                                                                aria-label="Close"
                                                                                data-bs-original-title=""
                                                                                title=""></button>
                                                                        </div>
                                                                        <div
                                                                            className="modal-body list-persons">
                                                                            <div className=" ps-0"
                                                                                 id={`remove-${contact.id}`}>
                                                                                <div
                                                                                    className="d-flex align-items-center justify-content-center">
                                                                                    <h6>Deseja remover esse
                                                                                        contato?</h6>

                                                                                </div>
                                                                                <div
                                                                                    className="d-flex align-items-center justify-content-center">
                                                                                    <button
                                                                                        style={{marginRight: 20}}
                                                                                        className="btn btn-secondary update-contact"
                                                                                        type="submit"
                                                                                        onClick={() => this.handleRemoveContact(contact.id)}
                                                                                        data-bs-original-title=""
                                                                                        title="">Remover
                                                                                    </button>
                                                                                    <button
                                                                                        className="btn btn-primary"
                                                                                        type="button"
                                                                                        data-bs-dismiss="modal"
                                                                                        data-bs-original-title=""
                                                                                        title="">Cancelar
                                                                                    </button>
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>


                                                    ))}

                                                </>
                                            ))}
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 box-col-6">
                            <div className="email-left-aside">
                                <div className="card" style={{ height: "100%"}} ref={`#pills-contacts`}>
                                    <div className="card-body">
                                        <div className="email-app-sidebar left-bookmark">
                                            <div className="media">
                                                <div className="media-size-email">
                                                    <img className="me-3 rounded-circle" src={user_avatar} alt=""/>
                                                </div>
                                                <div>
                                                    <h6 className="f-w-600">{user.name}</h6>
                                                    <p> {instance_number_phone}</p>
                                                </div>
                                            </div>
                                            <ul className="nav main-menu contact-options">
                                                <li className="nav-item">
                                                    <button className="badge-light-secondary  btn-block btn-mail w-100"
                                                            type="button" data-bs-toggle="modal"
                                                            data-bs-target="#exampleModal" data-bs-original-title=""
                                                            title="">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                             viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                             strokeWidth="2" strokeLinecap="round"
                                                             strokeLinejoin="round"
                                                             className="feather feather-users me-2">
                                                            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                                            <circle cx="9" cy="7" r="4"></circle>
                                                            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                                            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                                        </svg>
                                                        Novo contato
                                                    </button>

                                                    <div className="modal custom-modal fade" id="exampleModal"
                                                         tabIndex="-1" aria-labelledby="exampleModalLabel"
                                                         style={{display: "none"}} aria-hidden="true">
                                                        <div className="modal-dialog modal-lg" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title"
                                                                        id="exampleModalLabel">Novo Contato</h5>
                                                                    <button className="btn-close" type="button"
                                                                            data-bs-dismiss="modal" aria-label="Close"
                                                                            data-bs-original-title="" title=""></button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <form onSubmit={this.handleSaveSubmit}
                                                                          className="form-bookmark needs-validation"
                                                                          id="bookmark-form" noValidate="">
                                                                        <div
                                                                            className="row g-2">
                                                                            <div
                                                                                className="mt-0 mb-3 col-md-12">

                                                                                <label>Nome</label>
                                                                                <div
                                                                                    className="row">
                                                                                    <div
                                                                                        className="col-sm-12">
                                                                                        <input
                                                                                            className="form-control"
                                                                                            id="name"
                                                                                            name="name"
                                                                                            type="text"
                                                                                            required
                                                                                            placeholder="Por favor, digite o nome"
                                                                                            onChange={this.handleChangeName}
                                                                                            data-bs-original-title=""
                                                                                            title="Por favor, digite o nome"/>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="mt-0 mb-3 col-md-12">
                                                                                <label>Email </label>
                                                                                <input
                                                                                    className="form-control"
                                                                                    id="email"
                                                                                    name="email"
                                                                                    type="email"
                                                                                    placeholder="Por favor, digite o endereço de e-mail."
                                                                                    onChange={this.handleChangeEmail}
                                                                                    autoComplete="off"
                                                                                    data-bs-original-title=""
                                                                                    title="Por favor, digite o endereço de e-mail."/>
                                                                            </div>
                                                                            <div
                                                                                className="mt-0 mb-20 col-md-12">
                                                                                <label>Telefone</label>
                                                                                <div
                                                                                    className="row">
                                                                                    <div
                                                                                        className="col-sm-6">
                                                                                        <InputMask
                                                                                            mask="+55 (99) 9 9999-9999"
                                                                                            maskChar="_"
                                                                                            className="form-control"
                                                                                            id="num_phone"
                                                                                            name="num_phone"
                                                                                            type="phone"
                                                                                            required
                                                                                            placeholder="Por favor, digite o número de telefone."
                                                                                            onChange={this.handleChangePhone}
                                                                                            title="Por favor, digite o número de telefone."
                                                                                            autoComplete="off"
                                                                                            data-bs-original-title=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="mb-20 col-sm-6">
                                                                                        <select className="form-control"
                                                                                                onChange={this.handleChangePhoneType}>
                                                                                            <option
                                                                                                value="mobile">Celular
                                                                                            </option>
                                                                                            <option
                                                                                                value="home">Residência
                                                                                            </option>
                                                                                            <option
                                                                                                value="others">outros
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            id={`saveMoreinfo`}
                                                                            className="row g-2 more-data"
                                                                            style={{display: "none"}}>

                                                                            <div className="mb-20 col-sm-12">
                                                                                <label>Agrupamento</label>
                                                                                <select className="form-control"
                                                                                        onChange={this.handleChangeTag}>
                                                                                    <option defaultValue>selecione qual
                                                                                        Agrupamento
                                                                                        deseja associar
                                                                                    </option>

                                                                                    {Object.values(tags).map(tag => (

                                                                                        <option
                                                                                            key={tag.id}
                                                                                            value={tag.id}>
                                                                                            {tag.title}
                                                                                        </option>
                                                                                    ))}

                                                                                </select>
                                                                            </div>
                                                                            {currentCompany.type_company === "advocacia" &&
                                                                                <div className="mb-3 col-md-12">
                                                                                    <label>Interresse</label>
                                                                                    <select
                                                                                        id="opt_interest"
                                                                                        name="opt_interest"
                                                                                        title="Por favor, selecione o Interresse."

                                                                                        className="form-control">
                                                                                        onChange={this.handleChangeInterest}
                                                                                        <option
                                                                                            selected>selecione
                                                                                            o
                                                                                            Interresse
                                                                                        </option>
                                                                                        <option
                                                                                            value="labor_lawyer">Advogado
                                                                                            Trabalhista
                                                                                        </option>
                                                                                        <option
                                                                                            value="civil_lawyer">Advogado
                                                                                            Civil
                                                                                        </option>
                                                                                        <option
                                                                                            value="criminal_lawyer">Advogado
                                                                                            Criminal
                                                                                        </option>
                                                                                        <option
                                                                                            value="family_lawyer">Advogado
                                                                                            de
                                                                                            Família
                                                                                        </option>
                                                                                        <option
                                                                                            value="tax_lawyer">Advogado
                                                                                            Tributarista
                                                                                        </option>
                                                                                        <option
                                                                                            value="environmental_lawyer">Advogado
                                                                                            Ambiental
                                                                                        </option>
                                                                                        <option
                                                                                            value="business_lawyer">Advogado
                                                                                            Empresarial
                                                                                        </option>
                                                                                        <option
                                                                                            value="real_estate_lawyer">Advogado
                                                                                            Imobiliário
                                                                                        </option>
                                                                                        <option
                                                                                            value="social_security_lawyer">Advogado
                                                                                            Previdenciário
                                                                                        </option>
                                                                                        <option
                                                                                            value="consumer_lawyer">Advogado
                                                                                            do
                                                                                            Consumidor
                                                                                        </option>
                                                                                        <option
                                                                                            value="international_lawyer">Advogado
                                                                                            Internacional
                                                                                        </option>
                                                                                        <option
                                                                                            value="intellectual_property_lawyer">Advogado
                                                                                            de
                                                                                            Propriedade
                                                                                            Intelectual
                                                                                        </option>
                                                                                        <option
                                                                                            value="military_lawyer">Advogado
                                                                                            Militar
                                                                                        </option>
                                                                                        <option
                                                                                            value="administrative_lawyer">Advogado
                                                                                            Administrativo
                                                                                        </option>
                                                                                        <option
                                                                                            value="human_rights_lawyer">Advogado
                                                                                            de
                                                                                            Direitos
                                                                                            Humanos
                                                                                        </option>

                                                                                    </select>
                                                                                </div>
                                                                            }

                                                                            {currentCompany.type_company === "advocacia" &&
                                                                                <div className="mb-30 col-md-12">
                                                                                    <label>Número
                                                                                        do
                                                                                        Processo</label>
                                                                                    <div
                                                                                        className="row">
                                                                                        <div
                                                                                            className="col-sm-12">
                                                                                            <InputMask

                                                                                                onChange={this.handleChangeNumProcess}
                                                                                                mask="9999999-99.9999.9.99.9999"
                                                                                                maskChar="_"
                                                                                                className="form-control"
                                                                                                id="num_Process"
                                                                                                name="num_Process"
                                                                                                type="text"
                                                                                                placeholder="Por favor, digite o número do Processo"
                                                                                                title="Por favor, digite o número do Processo."
                                                                                                autoComplete="off"
                                                                                                data-bs-original-title=""
                                                                                            />
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            }

                                                                            {currentCompany.type_company === "advocacia" &&
                                                                                <div className="mb-30 col-md-12">
                                                                                    <label>Valor da Ação</label>
                                                                                    <div
                                                                                        className="row">
                                                                                        <div
                                                                                            className="col-sm-12">
                                                                                            <NumericFormat

                                                                                                thousandSeparator="."
                                                                                                decimalSeparator=","
                                                                                                prefix="R$ "
                                                                                                decimalScale={2}
                                                                                                fixedDecimalScale={true}
                                                                                                allowNegative={false}
                                                                                                onValueChange={this.handleInputChangeShareValue}
                                                                                                className="form-control"
                                                                                                id="share_value"
                                                                                                name="share_value"
                                                                                                placeholder="Por favor, digite o valor"
                                                                                            />
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            }


                                                                            <div
                                                                                className="mb-3 col-md-12">
                                                                                <label>Endereço</label>

                                                                                <div
                                                                                    className="row">
                                                                                    <div
                                                                                        className=" mb-10 col-12">
                                                                                        <div>
                                                                                            <input
                                                                                                className="form-control"
                                                                                                type="text"
                                                                                                id="address"
                                                                                                name="address"
                                                                                                onChange={this.handleChangeAddress}
                                                                                                placeholder="Endereço"
                                                                                                data-bs-original-title=""
                                                                                                title=""/>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div
                                                                                        className=" mb-10 col-sm-6">
                                                                                        <div>
                                                                                            <input
                                                                                                className="form-control"
                                                                                                id="city"
                                                                                                name="city"
                                                                                                type="text"
                                                                                                onChange={this.handleChangeCity}
                                                                                                placeholder="Cidade"
                                                                                                data-bs-original-title=""
                                                                                                title=""/>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className=" mb-10 col-sm-6">

                                                                                        <select
                                                                                            id="state"
                                                                                            name="state"
                                                                                            onChange={this.handleChangeState}
                                                                                            className="form-control">
                                                                                            <option
                                                                                                selected>Estado
                                                                                            </option>
                                                                                            {estadosBrasil.map((estado) => (

                                                                                                <option
                                                                                                    key={estado.sigla}
                                                                                                    value={estado.sigla}>
                                                                                                    {estado.sigla}
                                                                                                </option>
                                                                                            ))}
                                                                                        </select>


                                                                                    </div>


                                                                                    <div
                                                                                        className=" mb-10 col-sm-6">
                                                                                        <div>
                                                                                            <InputMask
                                                                                                mask="99999-999"
                                                                                                maskChar="_"
                                                                                                className="form-control"
                                                                                                id="cep"
                                                                                                name="cep"
                                                                                                type="text"
                                                                                                onChange={this.handleChangeCep}
                                                                                                placeholder="Digite o CEP"
                                                                                                data-bs-original-title=""
                                                                                                title=""/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <a className="ps-0 edit-information"
                                                                           href="#"
                                                                           onClick={() => this.openMoreSave("saveMoreinfo")}
                                                                           data-bs-original-title=""
                                                                           id={`moreInfoLabal`}
                                                                           title="">Adicionar
                                                                            mais
                                                                            informações</a>

                                                                        <div
                                                                            className="d-flex align-items-center justify-content-center">
                                                                            <button
                                                                                style={{marginRight: 20}}
                                                                                className="btn btn-secondary update-contact"
                                                                                type="submit"
                                                                                data-bs-original-title=""
                                                                                title="">Salvar
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-primary"
                                                                                type="button"
                                                                                data-bs-dismiss="modal"
                                                                                data-bs-original-title=""
                                                                                title="">Cancelar
                                                                            </button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="nav-item">
                                                    <span className="main-title">Agrupamentos</span>
                                                </li>
                                                <li>
                                                    <button className="btn btn-category" type="button"
                                                            data-bs-toggle="modal" data-bs-target="#addTagModal"
                                                            data-bs-original-title="" title="">
                                                        <span className="title"> + Adicionar Agrupamento</span>
                                                    </button>
                                                    <div
                                                        className="modal custom-modal fade"
                                                        id={`addTagModal`}
                                                        tabIndex="-1"
                                                        style={{display: "none"}}
                                                        aria-hidden="true">
                                                        <div className="modal-dialog modal-dialog-centered"
                                                             role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title"> Adicionar Agrupamento</h5>
                                                                    <button
                                                                        className="btn-close"
                                                                        type="button"
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"
                                                                        data-bs-original-title=""
                                                                        title=""></button>
                                                                </div>

                                                                <div className="modal-body list-persons">

                                                                    <form className="form-bookmark" action="#"
                                                                          onSubmit={this.handleFormTagSubmit}
                                                                          acceptCharset="utf-8">
                                                                        <div className="row g-2">
                                                                            <div className="mb-3 col-md-12">
                                                                                <input className="form-control"
                                                                                       type="text" required="true"
                                                                                       placeholder="Digite o nome da Tag"
                                                                                       autoComplete="off"
                                                                                       value={inputTitle}
                                                                                       onChange={this.handleInputTagChange}
                                                                                       name="tag_title"
                                                                                       data-bs-original-title=""
                                                                                       title=""/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="mt-10 ps-0">
                                                                            <div
                                                                                className="d-flex align-items-center justify-content-center">
                                                                                <button
                                                                                    style={{marginRight: 20}}
                                                                                    className="btn btn-secondary "
                                                                                    type="submit"
                                                                                    data-bs-original-title=""
                                                                                    title="">Salvar
                                                                                </button>
                                                                                <button
                                                                                    className="btn btn-primary"
                                                                                    type="button"
                                                                                    data-bs-dismiss="modal"
                                                                                    data-bs-original-title=""
                                                                                    title="">Cancelar
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </form>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="modal custom-modal  fade" id="addTagModal"
                                                         tabIndex="-1"
                                                         aria-labelledby="addTagModalLabel1"
                                                         aria-hidden="true">
                                                        <div className="modal-dialog" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title"
                                                                        id="addTagModalLabel1">Adicionar
                                                                        Agrupamento</h5>
                                                                    <button className="btn-close" type="button"
                                                                            data-bs-dismiss="modal" aria-label="Close"
                                                                            data-bs-original-title="" title=""></button>
                                                                </div>
                                                                <div className="modal-body">

                                                                    {/*form add tag*/}
                                                                    <form className="form-bookmark" action="#"
                                                                          onSubmit={this.handleFormTagSubmit}
                                                                          acceptCharset="utf-8">
                                                                        <div className="row g-2">
                                                                            <div className="mb-3 col-md-12">
                                                                                <input className="form-control"
                                                                                       type="text" required="true"
                                                                                       placeholder="Digite o nome da Tag"
                                                                                       autoComplete="off"
                                                                                       value={inputTitle}
                                                                                       onChange={this.handleInputTagChange}
                                                                                       name="tag_title"
                                                                                       data-bs-original-title=""
                                                                                       title=""/>
                                                                            </div>
                                                                        </div>
                                                                        <div className="modal-btn delete-action">
                                                                            <div className="row">
                                                                                <div className="col-6 mb-0">
                                                                                    <a
                                                                                        className="btn btn-primary continue-btn">Salvar</a>
                                                                                </div>
                                                                                <div className="col-6 mb-0">
                                                                                    <a data-dismiss="modal"
                                                                                       data-bs-dismiss="modal"
                                                                                       aria-label="Close"
                                                                                       className="btn btn-secondary cancel-btn">Cancelar</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <li className={'nav-item'} key="icontacts">

                                                        <a className={`contact-tab  nav-link`}
                                                           data-bs-toggle="pill"
                                                           id={`pills-icontacts-tab`}
                                                           href={`#pills-icontacts`}
                                                           role="tab"
                                                           aria-controls={`pills-icontacts`}
                                                           aria-selected={"true"}
                                                           onClick={() => this.handleSelectedTag("pills-icontacts")}
                                                        >

                                                            <span className="title">Contatos</span>
                                                        </a>

                                                    </li>
                                                </li>

                                            </ul>
                                            <ul className="nav main-menu contact-options" id="tagTabs" role="tablist">
                                                {Object.values(tags).map(tag => (
                                                    <li className={'nav-item'} key={tag.id}>

                                                        <a className={`contact-tab  nav-link`}
                                                           data-bs-toggle="pill"
                                                           id={`pills-${tag.id}-tab`}
                                                           href={`#pills-${tag.id}`}
                                                           role="tab"
                                                           aria-controls={`pills-${tag.id}`}
                                                           aria-selected={first_tag === tag.id ? "true" : "false"}
                                                           onClick={() => this.handleSelectedTag(tag.id)}
                                                        >

                                                            <span className="title">{tag.title}</span>
                                                            <div className="delete-icon">
                                                                    <span style={{color: "#2c323f"}}
                                                                          data-bs-toggle="modal"
                                                                          data-bs-target={`#removeTagModal-${tag.id}`}
                                                                          data-bs-original-title="" title=""><i
                                                                        className='bx bx-trash'></i></span>

                                                                <div
                                                                    className="modal custom-modal fade"
                                                                    id={`removeTagModal-${tag.id}`}
                                                                    tabIndex="-1"
                                                                    style={{display: "none"}}
                                                                    aria-hidden="true">
                                                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                                                        <div className="modal-content">
                                                                            <div className="modal-header">
                                                                                <h5 className="modal-title"> Deletar</h5>
                                                                                <button
                                                                                    className="btn-close"
                                                                                    type="button"
                                                                                    data-bs-dismiss="modal"
                                                                                    aria-label="Close"
                                                                                    data-bs-original-title=""
                                                                                    title=""></button>
                                                                            </div>

                                                                            <div className="modal-body list-persons">
                                                                                <div
                                                                                    className="d-flex align-items-center justify-content-center">
                                                                                    <p>Deseja deletar
                                                                                        - {tag.title} ?</p>
                                                                                </div>

                                                                                <div className="mt-10 ps-0">
                                                                                    <div
                                                                                        className="d-flex align-items-center justify-content-center">
                                                                                        <button
                                                                                            style={{marginRight: 20}}
                                                                                            className="btn btn-secondary "
                                                                                            type="submit"
                                                                                            onClick={() =>this.handleDeleteTag(tag.id)}
                                                                                            data-bs-original-title=""
                                                                                            title="">Deletar
                                                                                        </button>
                                                                                        <button
                                                                                            className="btn btn-primary"
                                                                                            type="button"
                                                                                            data-bs-dismiss="modal"
                                                                                            data-bs-original-title=""
                                                                                            title="">Cancelar
                                                                                        </button>
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </a>

                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        );
    }

}

export default ContactRoom;