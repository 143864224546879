import React, { useState, useEffect } from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import '././style/FilePreview.css';





// Componente principal que exibe a tarja com a opção de download e abre em uma nova aba
function FilePreview({ fileUrl, extension }) {
    const [fileType, setFileType] = useState('');
    const [isHovered, setIsHovered] = useState(false);
    const [error, setError] = useState(false);

    // useEffect para definir o tipo de arquivo
    useEffect(() => {
        if (extension.includes("vnd.openxmlformats-officedocument.wordprocessingml.document")) {
            setFileType("docx");
        } else {
            if (extension.includes("pdf")) {
                setFileType("pdf");
            }else {
                setFileType(extension.split('/')[1]);
            }

        }
    }, [extension]); // Adiciona 'extension' como dependência

    const docs = [
        { uri: fileUrl, fileType: fileType }
    ];


    return (
        <div className="file-preview"
             onMouseEnter={() => setIsHovered(true)}
             onMouseLeave={() => setIsHovered(false)}
        >
            <div style={{ height: 230 }}>
             <DocViewer documents={docs} pluginRenderers={DocViewerRenderers}  />
            </div>

            {/*<iframe
                src={fileUrl}
                style={{  display: error ? 'none' : 'block',top: 0, left: 0, width: '420', height: '230'}}
                frameBorder="0"
                onError={() => setError(true)}
                title="Content Preview"

            />*/}
            {error && <p>Erro ao carregar o conteúdo.</p>}
            {/* Exibe a tarja de download ao passar o mouse */}
            {isHovered && (
                <div className="download-overlay">

                    <a href={fileUrl} download target="_blank" rel="noopener noreferrer" className="download-link">
                        Baixar Arquivo
                    </a>
                </div>
            )}
        </div>
    );
}

export default FilePreview;
