import React, {useState, useEffect, useRef, Fragment} from "react";
import ReactFlow, {
    useNodesState,
    useEdgesState,
    useStoreState,
    useStoreActions,
    applyNodeChanges,
    applyEdgeChanges
} from "react-flow-renderer";

import $ from 'jquery';
import 'react-flow-renderer/dist/style.css';
import './style/FlowChatBot.css';
import './style/Dropzone.css';
import './style/RangeSlider.css';
import FlowChatBot from './FlowChatBot';
const getNodeId = () => `${String(+new Date()).slice(6)}`;
class FlowRoom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            isButtonActive: false,
            iconsOpen: false,
            isFerramentasSlide: false,

            inputValue: '',
            selectValue: 'Etiqueta 1',
            textareaValue: '' ,
            delayValue: 2,
            selectedOption: null,
            textAreas: [{ id: 1, value: '' }],
            maxTextAreas: 10,
            emojiPicker: [],
            emojiAreas: [{ value: '' }],
            isEmojiPickerVisible: false,
            emojiCode : [128526, 128565, 129299, 129322, 128516],

            enumTypeFlowNode: [
                'tag',
                'trigger',
                'finish',
                'redirect',
                'notify',
                'message',
                'smart_delay',
                'files'],

            botNodes: props.botNodes || [],
            botEdges: props.botEdges || [],
            nodes: props.botNodes || [],
            edges: props.botEdges || [],
            onSave: props.bot_published,
            clickInNode: false,
            nodeEdit: null
        }
        this.ferramentasRef = React.createRef();
        this.handleEmojiClick = this.handleEmojiClick.bind(this);
        this.handleEmojiPickerVisible = this.handleEmojiPickerVisible.bind(this);
        this.onNodesChange = this.onNodesChange.bind(this);
        this.onEdgesChange = this.onEdgesChange.bind(this);

    }
    openToggle = () =>{

        this.setState((prevState) => ({
            isButtonActive: !prevState.isButtonActive,
            iconsOpen: !prevState.iconsOpen,
        }));

        this.setState({ isFerramentasSlide: false });


    };

    removeClass = () => {
        if (this.ferramentasRef.current) {
            this.ferramentasRef.current.classList.remove('ferramentaslide');
        }
    };

    handleInputChange = (event) => {
        this.setState({ inputValue: event.target.value });
    }

    handleSelectChange = (event) => {
        this.setState({ selectValue: event.target.value });
    }

    handleTextareaChange = (event) => {
        this.setState({ textareaValue: event.target.value });
    }

    handleDelayChange = (event) => {
        this.setState({ delayValue: event.target.value });
    }

    clickInNode = () => {
        const { clickInNode } = this.state;
        this.setState({ clickInNode: !clickInNode });
    }

    onSave = () => {
        const { onSave } = this.state;
        this.setState({ onSave: !onSave });
    }
    nodeEdit = (nodeEdit) => {
        this.setState({ nodeEdit: nodeEdit });
    }
    handleEdges = (edges) => {
        this.setState({ edges: edges });
    }
    onNodesChange() {
        // Assuming applyNodeChanges is a function that applies changes to nodes
        const updatedNodes = useNodesState( this.state.botNodes);
        this.setState({ botNodes: updatedNodes });
    }

    onEdgesChange(changes) {
        // Assuming applyEdgeChanges is a function that applies changes to edges
        const updatedEdges = applyEdgeChanges(changes, this.state.botEdges);
        this.setState({ botEdges: updatedEdges });
    }
    mostrarOpcao = (numero) => {
        this.setState({ selectedOption: numero });
    };

    handleEmojiPickerVisible() {
        const { isEmojiPickerVisible } = this.state;
        this.setState({ isEmojiPickerVisible: !isEmojiPickerVisible });
    }
    handleAddTextArea = () => {
        this.setState((prevState) => {
            const newId =  getNodeId();
            if (newId <= prevState.maxTextAreas) {
                return {
                    textAreas: [...prevState.textAreas, { id: newId, value: '' }]
                };
            }
            return null;
        });
    };

    handleTextAreaChange = (id, event) => {
        const newTextAreas = this.state.textAreas.map((textArea) => {
            if (textArea.id === id) {
                return { ...textArea, value: event.target.value };
            }
            return textArea;
        });
        this.setState({ textAreas: newTextAreas });
    };

    handleDeleteTextArea = (id) => {
        this.setState((prevState) => {
            const newTextAreas = prevState.textAreas.filter(textArea => textArea.id !== id);
            return { textAreas: newTextAreas };
        });
    };

    handleEmojiClick = (emoji) => {
        const newEmojiAreas =  this.state.textareaValue +  emoji

        this.setState({
            textareaValue: newEmojiAreas,
            isEmojiPickerVisible: false
        });

    };
    renderEmojiPicker() {
        const { emojiCode } = this.state;

        return (
            <React.Fragment>
                <div className="emoji-arrow"></div>
                {emojiCode.map((code) => (
                    <span key={code} className='my-emoji' onClick={() => this.handleEmojiClick(String.fromCodePoint(code))}>
                        {String.fromCodePoint(code)}
                    </span>
                ))}
            </React.Fragment>
        );
    }


    onAddNode = () => {
        const { nodes, edges, enumTypeFlowNode, selectedOption, textareaValue, textAreas} = this.state;
        const id = getNodeId();
        let media = {}
        let message = ""
        let  action = {}
        let  type = enumTypeFlowNode[selectedOption - 1]
        let  newOptions = {}
        textAreas.length

        if (textareaValue){
            message = textareaValue
        }


        if(type !== ""){
            const newNode = {
                id,
                type: 'custom',
                data: { message: message,
                    options: newOptions,
                    media: media,
                    action: action,
                    type: type
                }, // Use o estado options aqui
                position: {
                    x: 0,
                    y: 0 + (nodes.length + 1) * 20
                }
            };

            this.setState((prevState) => ({
                nodes: [...prevState.nodes, newNode]
            }));


        }



    }

    componentDidMount() {
        const { bot_nodes, bot_edges,  } = this.props;
        const { nodes } = this.state;




        if(Object.values(bot_nodes).length > 0 ){
            this.setState({botNodes:  JSON.parse(bot_nodes)})
        }
        if(Object.values(bot_edges).length > 0){
            this.setState({botEdges:  JSON.parse(bot_edges)})

        }
        if(nodes.length === 0){
            const initialNodes = [
                { id: "1",type: 'custom', data: { type: "input", message: "Start" ,options: {}}, position: { x: 100, y: 100 } }
            ];
            this.setState({nodes:  initialNodes})

        }

        const items = document.querySelectorAll('.flow-list__item');
        this.handleClick = () => {
            this.setState({ isFerramentasSlide: true });
            if (this.ferramentasRef.current) {
                this.ferramentasRef.current.classList.add('ferramentaslide');
            }
        };

        items.forEach(item => {
            item.addEventListener('click', this.handleClick);
        });
    }
    componentWillUnmount() {
        const items = document.querySelectorAll('.flow-list__item');
        items.forEach(item => {
            item.removeEventListener('click', this.handleClick);
        });
    }


    render() {
        const {  isButtonActive, iconsOpen,  isFerramentasSlide, inputValue, selectValue, textareaValue, delayValue , selectedOption, textAreas, isEmojiPickerVisible,onSave,nodes, edges } = this.state;
        const {  bot_name, bot_id,  instance_id, image_doc_url,image_video_url,image_mp3_url,image_audio_url,image_image_url } = this.props;
        return (<div className="row">
            <div className="col-xl-12 col-md-12">
                <div id="wrapper">
                    <div>
                        <div id="toolbar">
                            <div onClick={this.openToggle} className={`button ${isButtonActive ? 'active' : ''}`}></div>
                            <div className={`icons ${iconsOpen ? 'open' : ''}`}>
                                <div className="flow-list__container">
                                    <div onClick={() => this.mostrarOpcao(1)} className="flow-list__item">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             width="24" height="24" viewBox="0 0 24 24" fill="#EC5858">
                                            <path
                                                d="M12.7886 3.4931C12.4671 2.83563 11.5329 2.83563 11.2114 3.4931L8.86562 8.29027L3.73049 9.17547C3.04016 9.29447 2.75957 10.1365 3.23978 10.6481L6.92912 14.5785L6.142 19.9897C6.03817 20.7035 6.78644 21.2332 7.42197 20.8958L12 18.4657L16.578 20.8958C17.2136 21.2332 17.9618 20.7035 17.858 19.9897L17.0709 14.5785L20.7602 10.6481C21.2404 10.1365 20.9598 9.29447 20.2695 9.17547L15.1344 8.29027L12.7886 3.4931Z"
                                                fill="#EC5858"></path>
                                        </svg>
                                        <div className="flow-list__item-text">Etiquetar</div>
                                    </div>

                                    <div onClick={() => this.mostrarOpcao(2)} className="flow-list__item">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M17.1533 12.9488L14.5147 11.0708L16.6292 3.56061C16.736 3.20918 16.6038 2.82589 16.3074 2.62696C16.0117 2.42866 15.6269 2.46581 15.3716 2.7163L6.76406 10.7191C6.57601 10.8895 6.47903 11.1475 6.50382 11.409C6.52779 11.6698 6.67069 11.9035 6.88725 12.0348L9.43482 13.5797L7.06044 20.3991C6.94655 20.7336 7.05207 21.1072 7.32175 21.3222L7.343 21.3382C7.60975 21.5484 7.97294 21.5545 8.24477 21.3525L17.2053 14.277C17.4008 14.1174 17.5104 13.8662 17.4992 13.6046C17.4967 13.3392 17.3668 13.092 17.1533 12.9488Z"
                                                fill="#F5B715"></path>
                                        </svg>
                                        <div className="flow-list__item-text">Gatilho</div>
                                    </div>
                                    <div onClick={() => this.mostrarOpcao(3)} className="flow-list__item">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             width="24" height="24" viewBox="0 0 24 24" fill="#5175F5">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V7.99999C19 8.26521 18.8946 8.51956 18.7071 8.70709L14 13.4142V17C14 17.2652 13.8946 17.5196 13.7071 17.7071L11.7071 19.707C11.4211 19.993 10.991 20.0786 10.6173 19.9238C10.2436 19.769 10 19.4045 10 19V13.4142L5.29289 8.70709C5.10536 8.51956 5 8.26521 5 7.99999V5Z"
                                                  fill="#5175F5"></path>
                                        </svg>
                                        <div className="flow-list__item-text">Finalizar</div>
                                    </div>
                                    <div onClick={() => this.mostrarOpcao(4)} className="flow-list__item">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             width="24" height="24" viewBox="0 0 24 24" fill="#1FBADC">
                                            <path
                                                d="M16.7017 10.7013C16.9145 10.9036 17.1696 11.0161 17.4248 11.0161C17.7015 11.0161 17.999 10.9036 18.191 10.6556L20.9993 7.50352L18.2125 4.35239C17.8086 3.9021 17.1271 3.87923 16.7016 4.30754C16.2761 4.73495 16.2544 5.45614 16.6592 5.90643L17.0847 6.37959C15.6594 6.44731 14.3188 7.12275 13.3614 8.22561L11.5953 10.3188L9.82921 8.2476C8.80779 7.05415 7.38246 6.37872 5.87228 6.37872H4.06381C3.4679 6.37872 3 6.87386 3 7.50444C3 8.13503 3.4679 8.63017 4.06381 8.63017H5.87228C6.76571 8.63017 7.63837 9.03561 8.23435 9.7559L10.1492 12.0073L8.23435 14.2588C7.63845 14.9791 6.76664 15.3845 5.87228 15.3845H4.06381C3.4679 15.3845 3 15.8797 3 16.5103C3 17.1408 3.4679 17.636 4.06381 17.636H5.87228C7.38322 17.636 8.8086 16.9605 9.83007 15.7671L11.5962 13.6959L13.3622 15.7671C14.3197 16.8928 15.6603 17.5683 17.0856 17.6131L16.66 18.0863C16.2561 18.5366 16.2769 19.2568 16.7024 19.6852C16.9152 19.8874 17.1703 20 17.4255 20C17.7022 20 17.9998 19.8874 18.1918 19.6394L21 16.4874L18.2124 13.3583C17.8085 12.908 17.127 12.8851 16.7015 13.3135C16.2759 13.7409 16.2543 14.4621 16.6591 14.9123L17.063 15.3626C16.2543 15.2949 15.4889 14.9123 14.9354 14.2598L13.0205 12.0083L14.9354 9.75688C15.4889 9.10432 16.2543 8.72085 17.063 8.65403L16.6591 9.10432C16.2759 9.55373 16.2759 10.274 16.7015 10.7015L16.7017 10.7013Z"
                                                fill="#1FBADC"></path>
                                        </svg>
                                        <div className="flow-list__item-text">Redirecionar</div>
                                    </div>
                                    <div onClick={() => this.mostrarOpcao(5)} className="flow-list__item">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M14.4192 11.4071H14.5061C14.8807 11.4083 15.232 11.2251 15.4465 10.9178C15.661 10.6106 15.712 10.2187 15.5833 9.86619C15.0569 8.44235 13.8418 7.38491 12.3601 7.06027C10.8771 6.73445 9.33033 7.18782 8.25789 8.26149C7.1842 9.33517 6.73201 10.8819 7.05781 12.3636C7.38364 13.8466 8.44225 15.0617 9.86608 15.5856C10.2186 15.7155 10.6116 15.6645 10.9189 15.4488C11.2262 15.2332 11.4082 14.8807 11.407 14.5062V14.4192C11.3954 13.9357 11.0917 13.5067 10.6383 13.3363C9.83827 13.0174 9.31766 12.2383 9.32923 11.3767C9.35938 10.259 10.2591 9.35926 11.3769 9.32914C12.2384 9.31522 13.0199 9.83466 13.3399 10.6347C13.508 11.088 13.9359 11.3941 14.4194 11.4069L14.4192 11.4071Z"
                                                fill="#F169D3"></path>
                                            <path
                                                d="M17.863 10.4249C17.9442 10.9873 18.4253 11.4058 18.9935 11.407H19.0805C19.4086 11.4082 19.7217 11.269 19.9385 11.0232C20.1565 10.7774 20.2573 10.4504 20.2168 10.1246C19.8991 7.9344 18.7755 5.94 17.0677 4.53246C15.3598 3.12486 13.1882 2.40367 10.9768 2.51035C8.76565 2.61586 6.67277 3.54229 5.1076 5.10756C3.5423 6.67283 2.61587 8.76562 2.51035 10.9767C2.40367 13.1878 3.12487 15.3596 4.53249 17.0675C5.94011 18.7754 7.93444 19.8989 10.1247 20.2165C10.4494 20.2571 10.7764 20.1562 11.0222 19.9394C11.268 19.7226 11.4083 19.4107 11.4071 19.0826V18.9968C11.406 18.4286 10.9874 17.9463 10.425 17.8663C8.82496 17.6216 7.37335 16.7938 6.34959 15.5404C5.32577 14.287 4.80516 12.6985 4.88631 11.0821C4.96746 9.46564 5.64578 7.93753 6.78904 6.79327C7.93346 5.64886 9.46165 4.97059 11.0767 4.88825C12.693 4.80593 14.2816 5.3277 15.536 6.35035C16.7894 7.373 17.6184 8.82472 17.863 10.4249Z"
                                                fill="#F169D3"></path>
                                            <path
                                                d="M17.5394 15.8595H19.7181C20.0544 15.8606 20.3755 15.7203 20.6016 15.4722C20.8277 15.2229 20.9379 14.8913 20.9054 14.5562C20.8277 13.9348 20.2932 13.4721 19.6671 13.4849H15.8594C14.548 13.4849 13.4847 14.5481 13.4847 15.8595V19.6671C13.472 20.2933 13.9346 20.8278 14.5561 20.9055C14.8912 20.9379 15.2228 20.8278 15.4721 20.6017C15.7202 20.3756 15.8605 20.0544 15.8594 19.7182V17.5395L19.4724 21.1513C19.9362 21.6162 20.6875 21.6162 21.1513 21.1513C21.6162 20.6875 21.6162 19.9362 21.1513 19.4724L17.5394 15.8595Z"
                                                fill="#F169D3"></path>
                                        </svg>
                                        <div className="flow-list__item-text">Notificar</div>

                                    </div>
                                    <div onClick={() => this.mostrarOpcao(6)} className="flow-list__item">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             width="24" height="24" viewBox="0 0 24 24" fill="#3ABA38">
                                            <path
                                                d="M4.42402 10.7536L6.77615 11.6854C6.51086 12.3251 6.2994 12.9844 6.1434 13.6566L6.05391 14.0441L9.42601 17.2688L9.83148 17.1856V17.1848C10.5339 17.0365 11.2237 16.8343 11.8929 16.5808L12.8678 18.829L12.8686 18.8283C12.8876 18.8737 12.9295 18.907 12.9794 18.9161C13.0301 18.9251 13.0816 18.91 13.1172 18.8752L14.591 17.4665H14.5918C15.078 17.0017 15.3354 16.3613 15.3014 15.7051L15.2483 14.7997C17.9773 12.8733 20.738 9.49576 21.4903 3.60133C21.5339 3.30157 21.4286 2.99954 21.2053 2.78534C20.9819 2.57111 20.6668 2.46893 20.3531 2.50829C14.188 3.2327 10.6518 5.87446 8.63726 8.47461L7.69247 8.42844C7.00666 8.39286 6.33747 8.63509 5.84964 9.09683L4.37586 10.5055C4.33389 10.5396 4.31488 10.5918 4.32438 10.6433C4.33468 10.6948 4.37269 10.7364 4.42417 10.7538L4.42402 10.7536ZM13.8798 7.31924C14.4025 6.82192 15.1865 6.67431 15.8676 6.94454C16.5486 7.21477 16.9921 7.84987 16.9921 8.55459C16.9921 9.26007 16.5486 9.89517 15.8676 10.1654C15.1865 10.4356 14.4025 10.288 13.8798 9.78994C13.5361 9.46294 13.3429 9.0186 13.3429 8.55459C13.3429 8.09134 13.5361 7.647 13.8798 7.31924ZM3.98449 17.5261C3.59406 17.3951 3.17513 17.3633 2.76807 17.433C2.69442 17.4481 2.61761 17.4261 2.56534 17.3747C2.49961 17.3126 2.48139 17.2172 2.5202 17.1362C2.95259 16.2491 4.09772 14.4642 6.17187 15.9062C6.19404 15.9251 6.20592 15.9516 6.20592 15.9796C6.20592 16.0076 6.19404 16.0348 6.17187 16.053C5.60881 16.4769 5.29044 17.1317 5.3142 17.8167C5.31736 17.8992 5.38626 17.9651 5.47258 17.9681C6.18532 17.9954 6.87034 17.6979 7.31701 17.1658C7.33681 17.1423 7.36611 17.1294 7.397 17.1294C7.42867 17.1294 7.45797 17.1423 7.47777 17.1658C7.76604 17.4935 8.55797 18.5631 7.68051 19.5956C7.29722 20.0361 6.7476 20.3147 6.15128 20.3707C5.29995 20.4577 3.72717 20.7234 3.08409 21.4251C3.03182 21.4842 2.94867 21.5114 2.86947 21.4955C2.78949 21.4789 2.72534 21.4221 2.70317 21.3472C2.47588 20.6069 2.12347 18.9522 3.98452 17.526L3.98449 17.5261Z"
                                                fill="#3ABA38"></path>
                                        </svg>
                                        <div className="flow-list__item-text">Mensagem</div>
                                    </div>

                                    <div onClick={() => this.mostrarOpcao(7)} className="flow-list__item">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M12 3.5C9.7455 3.5 7.58371 4.39507 5.98889 5.9897C4.39507 7.58344 3.5 9.74513 3.5 11.9995C3.5 14.2539 4.39512 16.4165 5.98889 18.0103C7.58364 19.604 9.74548 20.5 12 20.5C14.2545 20.5 16.4163 19.604 18.0111 18.0103C19.6049 16.4166 20.5 14.2539 20.5 11.9995C20.4981 9.74608 19.6012 7.58633 18.0073 5.99255C16.4144 4.39882 14.2536 3.50285 12 3.5ZM15.6278 15.8838C15.4397 16.0978 15.17 16.2201 14.8865 16.2201C14.6428 16.222 14.4065 16.1339 14.2239 15.9727L11.3477 13.4228C11.1345 13.2356 11.0122 12.9659 11.0113 12.6815V7.74004C11.0113 7.19428 11.4542 6.75137 12 6.75137C12.5458 6.75137 12.9887 7.19428 12.9887 7.74004V12.1978L15.5388 14.4513C15.7473 14.6255 15.8743 14.8775 15.8919 15.1481C15.9086 15.4195 15.8131 15.6855 15.6278 15.8838Z"
                                                fill="#F7953B"></path>
                                        </svg>
                                        <div className="flow-list__item-text">Atraso inteligente</div>
                                    </div>

                                    <div onClick={() => this.mostrarOpcao(8)} className="flow-list__item">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M5.22252 3.5C4.29567 3.5 3.53527 4.26318 3.53527 5.19422V9.39727C3.53527 10.3276 4.29354 11.0985 5.22252 11.0985H9.3306C10.2596 11.0985 11.0249 10.3304 11.0249 9.39727V5.19422C11.0249 4.26105 10.2575 3.5 9.3306 3.5H5.22252Z"
                                                  fill="#683AC8"></path>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M14.7047 3.5C13.7778 3.5 13.0104 4.26105 13.0104 5.19422V9.39727C13.0104 10.3304 13.7757 11.0985 14.7047 11.0985H18.8127C19.7417 11.0985 20.5 10.3283 20.5 9.39727V5.19422C20.5 4.26389 19.7396 3.5 18.8127 3.5H14.7047Z"
                                                  fill="#683AC8"></path>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M5.1943 12.9015C4.26533 12.9015 3.5 13.6696 3.5 14.6027V18.8058C3.5 19.7382 4.26744 20.5 5.1943 20.5H9.30237C10.2292 20.5 10.9896 19.7368 10.9896 18.8058V14.6027C10.9896 13.6724 10.2314 12.9015 9.30237 12.9015H5.1943Z"
                                                  fill="#683AC8"></path>
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                  d="M14.6477 12.9015C13.7187 12.9015 12.9534 13.6696 12.9534 14.6027V18.8058C12.9534 19.7382 13.7208 20.5 14.6477 20.5H18.7557C19.6826 20.5 20.443 19.7368 20.443 18.8058V14.6027C20.443 13.6724 19.6847 12.9015 18.7557 12.9015H14.6477Z"
                                                  fill="#683AC8"></path>
                                        </svg>
                                        <div className="flow-list__item-text">Arquivos</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <FlowChatBot bot_name={bot_name} bot_id={bot_id} instance_id={instance_id}
                                     setClickInNode={this.clickInNode} onSave={onSave} setOnSave={this.onSave}
                                     setNodeEdit={this.nodeEdit} nodes={nodes} edges={edges} setEdges={this.handleEdges}
                                     onNodesChange={this.onNodesChange} onEdgesChange={this.onEdgesChange}/>
                    </div>

                </div>
                <div className={`ferramentas ${isFerramentasSlide ? 'ferramentaslide' : ''}`}
                     ref={this.ferramentasRef}>

                    <div id="meu-botao" onClick={() => this.removeClass} className="fecharferramentas">
                        <div className="fecharferramentabotao">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="#dfe5ece6"
                                 xmlns="http://www.w3.org/2000/svg"
                                 preserveAspectRatio="xMidYMid meet">
                                <path d="M14 15.9174L10.0656 12.0496L14 8.08264V6L8 12.0496L14 18V15.9174Z" fill="#333">
                                </path>
                            </svg>
                        </div>
                    </div>

                    <div className="opcoes">
                        <div id="opcao1" className="opcao" style={{display: selectedOption === 1 ? 'block' : 'none'}}>
                            <h2>Etiquetas</h2>
                            <p>Selecione a etiqueta que deseja adicionar ao contato.</p>
                            <select className="form-select digits" id="exampleFormControlSelect9" value={selectValue}
                                    onChange={this.handleSelectChange}>
                                <option>Etiqueta 1</option>
                                <option>Etiqueta 2</option>
                                <option>Etiqueta 3</option>
                                <option>Etiqueta 4</option>
                                <option>Etiqueta 5</option>
                            </select>
                            <button className="btn btn-success btn-sm" type="button">Adicionar</button>
                        </div>

                        <div id="opcao2" className="opcao" style={{display: selectedOption === 2 ? 'block' : 'none'}}>
                            <h2>Gatilho</h2>
                            <p>Por favor, adicione aqui frase ou palavra que serão usadas como gatilho para iniciar o
                                fluxo.</p>
                            <div>
                                <div>
                                <textarea className="textareamensgem" placeholder="Quero falar com um especialista"
                                          value={textareaValue.value} onChange={this.handleTextareaChange}
                                          style={{height: 49}}></textarea>
                                </div>
                            </div>
                            <button className="btn btn-success btn-sm" type="button">Salvar</button>
                        </div>

                        <div id="opcao3" className="opcao"
                             style={{display: selectedOption === 3 ? 'block' : 'none'}}>3
                        </div>

                        <div id="opcao4" className="opcao" style={{display: selectedOption === 4 ? 'block' : 'none'}}>
                            <h2>Redirecionar</h2>
                            <p>Selecione para onde deseja redirecionar o contato quando ele chegar aqui.</p>
                            <select className="form-select digits" id="exampleFormControlSelect9">
                                <option>DEPARTAMENTO 1</option>
                                <option>DEPARTAMENTO 2</option>
                                <option>DEPARTAMENTO 3</option>
                                <option>DEPARTAMENTO 4</option>
                                <option>DEPARTAMENTO 5</option>
                            </select>
                            <button className="btn btn-success btn-sm" type="button">Salvar</button>
                        </div>

                        <div id="opcao5" className="opcao"
                             style={{display: selectedOption === 5 ? 'block' : 'none'}}>5
                        </div>

                        <div id="opcao6" className="opcao" style={{display: selectedOption === 6 ? 'block' : 'none'}}>
                            <h2>Mensagem</h2>
                            <p>Por favor, adicione aqui uma mensagem a ser enviada. Se preferir, adicione também
                                respostas
                                pré-programadas.</p>
                            <div id="mensagem">
                                <div id="textarea1">
                                    <textarea className="textareamensgem" id="text-area"
                                              value={textareaValue} onChange={this.handleTextareaChange}
                                              placeholder="Digite seu texto aqui..."></textarea>

                                    <div onClick={this.handleEmojiPickerVisible} className="emoji">
                                        <span>🙂</span>
                                        {isEmojiPickerVisible &&
                                            <div id="emoji-picker"
                                                 style={{display: "block"}}>{this.renderEmojiPicker()}</div>}

                                    </div>
                                </div>


                                {textAreas.map((textArea) => (
                                    <div key={textArea.id} id={`textarea${textArea.id}`}
                                         className="proximotextareaflex">
                                        <input
                                            type="text"
                                            placeholder="Digite seu texto aqui..."
                                            className="proximostextarea textareamensgem"
                                            value={textArea.value}
                                            onChange={(e) => this.handleTextAreaChange(textArea.id, e)}
                                        />
                                        <div className="buttonGroup">
                                            <button className="deleteButton"
                                                    onClick={() => this.handleDeleteTextArea(textArea.id)}>Apagar
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <button className="botaoadd" id="addButton" onClick={this.handleAddTextArea}
                                    disabled={textAreas.length >= this.state.maxTextAreas}>Adicionar Resposta
                            </button>
                            <button className="btn btn-success btn-sm"  onClick={this.onAddNode} type="button">Salvar</button>
                        </div>

                        <div id="opcao7" className="opcao" style={{display: selectedOption === 7 ? 'block' : 'none'}}>
                            <h2>Atraso de envio</h2>
                            <p>Por favor, selecione um valor em segundos de atraso deseja adicionar para que a próxima
                                mensagem seja enviada.</p>
                            <div className="slider-container">
                                <input type="range" min="2" max="30" value={delayValue}
                                       onChange={this.handleDelayChange}
                                       className="slider" id="rangeSlider"/>
                                <p className="slider-value"><span id="sliderValue">{delayValue}</span>s</p>
                            </div>
                            <button className="btn btn-success btn-sm" type="button">Adicionar atraso</button>
                        </div>

                        <div id="opcao8" className="opcao" style={{display: selectedOption === 8 ? 'block' : 'none'}}>
                            <h2>Anexos</h2>
                            <p>Selecione qual tipo de arquivo você deseja adicionar.</p>
                            <div className="arquivosanexo">
                                <div className="anexo">
                                    <img width="40" height="40"
                                         src={image_image_url}
                                         alt="Image"/>
                                    <p className="color-2 mb-0 pt-4">Imagem</p>
                                </div>
                                <div className="anexo">
                                    <img width="40" height="40"
                                         src={image_doc_url}
                                         alt="Documento"/>
                                    <p className="color-3 mb-0 pt-4">Documento</p>
                                </div>
                                <div className="anexo">
                                    <img width="40" height="40"
                                         src={image_audio_url}
                                         alt="Audio"/>
                                    <p className="color-1 mb-0 pt-4">Audio</p>
                                </div>
                                <div className="anexo">
                                    <img width="40" height="40"
                                         src={image_video_url}
                                         alt="Video"/>
                                    <p className="color-9 mb-0 pt-4">Video</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>);
    }
}

export default FlowRoom;