import React, { useState, useEffect } from 'react';
import Board from 'react-trello';
import axios from 'axios';
import CustomCard from './CustomCard';
import CustomLaneHeader from './CustomLaneHeader';
import CustomLaneFooter from './CustomLaneFooter';
import CustomNewCardForm from './CustomNewCardForm';
import CustomAddCardLink from './CustomAddCardLink';
import { ChromePicker } from 'react-color';
import "./style/kanban.css.scss";
import { Modal, Button, Form, Row, Col, ListGroup, Image, ProgressBar, Badge } from 'react-bootstrap';
import ImageWithFallback from "../ImageWithFallback";



const KanbanBoard = ({ externalData, currentUserId }) => {
    //const [data, setData] = useState(externalData);
    const [data, setData] = useState({
        lanes: [
            {
                id: 'lane1',
                draggable: false,
                title: 'Conversas',
                color:"#65bd52",
                cards: [
                    {id: 'Card1',  color:"#65bd52", title: 'Write Blog', links:{ chat:"",contact:""}, phone:"(62) 9 82651478",tags:[{code: "NEW_PROCESS", label: "Novo Processo"}, {code: "LEGAL_GUIDANCE", label: "Orientação Legal"}],users:[], avatar: 'https://via.placeholder.com/40' , avatar_fallback:  'https://via.placeholder.com/40',description: 'Transfer via NEFT', tasks: [],comments:[
                            {
                                id: 1,
                                user: 'Maria Silva',
                                userImage: 'https://via.placeholder.com/40',
                                user_image_fallback:  'https://via.placeholder.com/40',
                                text: 'Ótimo trabalho até agora!',
                                timestamp: new Date().toLocaleString(),
                            },
                            {
                                id: 2,
                                user: 'João Souza',
                                userImage:  'https://via.placeholder.com/40',
                                user_image_fallback:  'https://via.placeholder.com/40',
                                text: 'Precisamos revisar a última tarefa.',
                                timestamp: new Date().toLocaleString(),
                            }
                        ],archivedCard: true },
                    {id: 'Card2',  color:"#65bd52", title: 'Pay Rent', links:{chat:"",contact:""}, phone:"(62) 9 82651478",tags:[{code: "NEW_PROCESS", label: "Novo Processo"}, {code: "LEGAL_CONSULTATION", label: "Consulta Jurídica"}],users:[], avatar: 'https://via.placeholder.com/40' , avatar_fallback:  'https://via.placeholder.com/40',description: 'Transfer via NEFT', tasks: [],comments:[], metadata: {sha: 'be312a1'},archivedCard: false }
                ]
            },
            {
                id: 'lane3',
                title: 'Leads',
                draggable: true,
                color:"#5952bd",
                cards: [
                    {id: 'Card1',color:"#5952bd", title: 'Clean house', phone:"(62) 9 82001478",
                        links:{
                            chat:"698",
                            contact:""
                        },
                        users:[
                            {id: 2, name: "fulano de tal", avatar: 'https://via.placeholder.com/40',  avatar_fallback:  'https://via.placeholder.com/40'},
                            {id: 146, name: "fulan ", avatar: 'https://via.placeholder.com/40',  avatar_fallback:  'https://via.placeholder.com/40'},
                            {id: 147, name: "fulan 2 ", avatar: 'https://via.placeholder.com/40',  avatar_fallback:  'https://via.placeholder.com/40'}
                        ],
                        tags:[],
                        avatar:'https://via.placeholder.com/40',
                        avatar_fallback:  'https://via.placeholder.com/40',
                        description: 'Transfer via NEFT',
                        tasks: [
                            { id: 1, text: 'Task 1', completed: false },
                            { id: 2, text: 'Task 2', completed: false },
                            { id: 3, text: 'Task 3', completed: true }],
                        comments:[],
                        archivedCard: false }
                ]
            },
            {
                id: 'lane5',
                draggable: true,
                title: 'Ganhou',
                color:"#52BD7F",
                cards: []
            },
            {
                id: 'lane6',
                title: 'Perdeu',
                draggable: true,
                color:"#52BD7F",
                cards: []
            }
        ],
    });

    const [selectedCard, setSelectedCard] = useState(null);
    const [selectedCardUsers, setSelectedCardUsers] = useState(null);
    const [ selectedCardUsersCount,  setSelectedCardUsersCount] = useState(0);
    const [filteredCards, setFilteredCards] = useState(data);
    const [searchTerm, setSearchTerm] = useState(''); // Estado para o termo de pesquisa
    const [showArchived, setShowArchived] = useState(false);




    const [color, setColor] = useState('#fff');
    const [showModal, setShowModal] = useState(false);


    const [editTitle, setEditTitle] = useState('');
    const [titleLane, setTitleLane] = useState('');
    const [editDescription, setEditDescription] = useState('');

    const [members, setMembers] = useState([]);
    const [newMember, setNewMember] = useState('');
    const [tags, setTags] = useState([]);
    const [newTag, setNewTag] = useState('');

    const [tasks, setTasks] = useState([]);
    const [comments, setComments] = useState([]);
    const [newTask, setNewTask] = useState('');
    const [newComment, setNewComment] = useState('');



    const handleTaskToggle = (taskId) => {
        const updatedTasks = tasks.map(task =>
            task.id === taskId ? { ...task, completed: !task.completed } : task
        );
        setTasks(updatedTasks);
    };


    const handleAddTask = () => {
        if (newTask.trim()) {
            const newTaskObject = {
                id: tasks.length + 1,
                text: newTask,
                completed: false
            };
            setTasks([...tasks, newTaskObject]);
            setNewTask('');
        }
    };
    const handleEditTask = (id) => {
        const updatedText = prompt('Edit task:', tasks.find(task => task.id === id).text);
        if (updatedText) {
            setTasks(tasks.map(task => (task.id === id ? { ...task, text: updatedText } : task)));
        }
    };

    const handleDeleteTask = (id) => {
        setTasks(tasks.filter(task => task.id !== id));
    };

    // Função para calcular o progresso do checklist
    const calculateProgress = () => {
        const totalTasks = tasks.length;
        const completedTasks = tasks.filter(task => task.completed).length;
        return totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;
    };
    const handleAddComment = () => {
        if (newComment.trim()) {
            const newCommentObject = {
                id: comments.length + 1,
                user: 'Usuário Atual', // Aqui você pode pegar o nome do usuário logado
                userImage: 'https://via.placeholder.com/40', // Placeholder para a foto de perfil
                text: newComment,
                timestamp: new Date().toLocaleString()
            };
            setComments([...comments, newCommentObject]);
            setNewComment('');
        }
    };
    const handleEditComment = (id) => {
        const updatedText = prompt('Edit comment:', comments.find(comment => comment.id === id).text);
        if (updatedText) {
            setComments(comments.map(comment => (comment.id === id ? { ...comment, text: updatedText } : comment)));
        }
    };

    const handleDeleteComment = (id) => {
        setComments(comments.filter(comment => comment.id !== id));
    };
    const handleAddMember = () => {
        if (newMember.trim() && !members.includes(newMember)) {
            setMembers([...members, newMember]);
            setNewMember('');
        }
    };

    const handleAddTag = () => {
        if (newTag.trim() && !tags.includes(newTag)) {
            setTags([...tags, newTag]);
            setNewTag('');
        }
    };



    // Função para arquivar ou desarquivar um card
    const handleCardArchiveToggle = (laneId, cardId) => {
        const updatedLanes = data.lanes.map(lane => {
            if (lane.id === laneId) {
                return {
                    ...lane,
                    cards: lane.cards.map(card =>
                        card.id === cardId ? { ...card, archivedCard: !card.archivedCard } : card
                    )
                };
            }
            return lane;
        });
        setData({ lanes: updatedLanes });
    };


    // Função chamada ao clicar em um card
    const handleCardClick = (cardId, metadata, laneId) => {

        const lane = filteredCards.lanes.find(lane => lane.id === laneId);
        const card = lane.cards.find(card => card.id === cardId);

        debugger
        setSelectedCard(card);
        setShowModal(true);

        setSelectedCardUsers(selectedCard.users.slice(0, 2)); // Mostra os primeiros 4 usuários
        setSelectedCardUsersCount(selectedCard.users.length - selectedCardUsers.length); // Calcula o número de usuários restantes

    };

    // Função para fechar o modal
    const handleCloseModal = () => setShowModal(false);

    const handleSaveChanges = () => {
        // Salva as mudanças no card aqui, se necessário
        handleCloseModal();
    };


    const handleCardAdd = (laneId) => {
        console.log(`Adicionando card na lane: ${laneId}`);
        // Aqui você pode adicionar a lógica para inserir um novo card na lane correta
    };

    const applyFilters = (term, showArchivedState) => {
        // Aplica tanto o filtro de arquivados quanto o de pesquisa
        setFilteredCards({
            lanes: data.lanes.map((lane) => {
                let filtered = lane.cards.filter((card) =>
                    showArchivedState ? card.archivedCard : !card.archivedCard
                ); // Filtro de arquivados/ativos

                if (term) {
                    filtered = filtered.filter((card) =>
                        card.title.toLowerCase().includes(term.toLowerCase())
                    ); // Filtro de pesquisa
                }

                return { ...lane, cards: filtered };
            }),
        });
    };
    const handleSearch = (laneId, term) => {
        setSearchTerm(term); // Atualiza o termo de pesquisa
        applyFilters(term, showArchived); // Aplica os filtros
    };

    // Filtra cards com base no estado 'showArchived'
    const filteredData = {
        lanes: filteredCards.lanes.map(lane => ({
            ...lane,
            cards: showArchived
                ? lane.cards.filter(card => card.archivedCard) // Exibe apenas os arquivados
                : lane.cards.filter(card => !card.archivedCard) // Exibe apenas os ativos
        }))
    };
    const handleSaveSubmit = async (e) => {
        e.preventDefault();

        if (titleLane) {
            const newLane = {
                id: `lane${Date.now()}`, // Gera um ID único
                title: titleLane,
                color: color,
                label: '0/0', // Inicializa com 0 cards
                cards: [],
                addCardLink: 'Adicionar novo cartão',
            };

            // Atualiza as lanes com a nova lane
            setFilteredCards(prevData => ({
                lanes: [...prevData.lanes, newLane] // Adiciona a nova lane à lista existente
            }));
            closeModal("createLaneModal")
            setTitleLane('');
            setColor('#fff');
        }

    }
    const handleChangeNameLane = (e) => {
        const {  value } = e.target;
        setTitleLane(value);
    };
    const handleColorChange = (newColor) => {
        setColor(newColor.hex);
    };

    const closeModal = (id) => {
        document.getElementById(id).style.display = "none";
        var elementos = document.querySelectorAll(".modal-backdrop");

        elementos.forEach(function(elemento) {
            elemento.classList.remove("modal-backdrop");
        });
    }
    const openModal = (id) => {

        var myModal = new bootstrap.Modal(document.getElementById(id), {
            keyboard: false
        });
        myModal.show();

    };
    const setEventBus = (eventBus) => {
        console.log(`evemt: ${eventBus}`);
    }
    // Função que define se uma lane pode ser movida
    const canLaneDrag = (laneId) => {
        const lane = data.lanes.find(l => l.id === laneId);
        debugger
        return !lane.fixed; // Se `fixed` for true, retorna false (não movível)
    };
    const handleLaneDrag = (sourceLaneId, targetLaneId, position) => {
        const sourceLane = filteredCards.lanes.find(lane => lane.id === sourceLaneId);
        debugger
        if (sourceLane && sourceLane.laneDraggable) {
            console.log(`Lane ${sourceLane.title} está fixada e não pode ser movida.`);
            return false; // Impede o movimento se a lane estiver fixada
        }

        // Continue com o movimento se a lane não estiver fixada
        console.log(`Movendo lane de ${sourceLaneId} para ${targetLaneId}`);
        return true; // Permite o movimento
    };
    // Alterna a visualização entre cards arquivados e não arquivados
    const toggleShowArchived = () => {
        setShowArchived(!showArchived);
        applyFilters(searchTerm, !showArchived); // Aplica os filtros
    };

    const handleCardMove = (fromLaneId, toLaneId, cardId, index) => {



        axios.post('/kanbans/move_card', {
            cardId,
            fromLaneId,
            toLaneId,
            index
        }).then(response => {
                console.log('Cartão movido com sucesso:', response.data);

                // Agora, atualize o estado local das lanes para refletir essa mudança no frontend
                setFilteredCards(prevData => {
                    // Encontre a lane de origem e a de destino
                    const fromLaneIndex = prevData.lanes.findIndex(lane => lane.id === fromLaneId);
                    const toLaneIndex = prevData.lanes.findIndex(lane => lane.id === toLaneId);

                    // Clone as lanes para não mutar o estado diretamente
                    const updatedLanes = [...prevData.lanes];

                    // Encontre o cartão que será movido
                    const cardToMove = updatedLanes[fromLaneIndex].cards.find(card => card.id === cardId);

                    if (cardToMove) {
                        // Remover o cartão da lane de origem
                        updatedLanes[fromLaneIndex].cards = updatedLanes[fromLaneIndex].cards.filter(card => card.id !== cardId);

                        cardToMove.color = toLaneIndex.color

                        // Adicionar o cartão à lane de destino
                        updatedLanes[toLaneIndex].cards.splice(index, 0, cardToMove);
                    }

                    // Retornar o novo estado das lanes
                    return {
                        ...prevData,
                        lanes: updatedLanes
                    };
                });

            })
            .catch(error => {
                console.error('Erro ao mover cartão:', error);
            });
    };
    const handleTagColor = (s) => {
        switch (s) {
            case 'simulation':
            case 'NEW_PROCESS':
            case 'IN_PROGRESS':
                return 'badge-primary';
            case 'approved':
                return 'badge-success';
            case 'reproved':
                return 'badge-danger';
            case 'resend_documents':
            case 'under_analysis':
            case 'send_documents':
            case 'company_analysing':
            case 'awaiting_contract':
            case 'CLOSED':
                return 'badge-warning';
            case 'REPASSADO_PARA_EMPRESA':
            case 'PRE_APROVADO':
                return 'badge-success';
            case 'hired':
            case 'fill_data':
                return 'badge-info';
            case 'released':
                return 'badge-purple';
            default:
                return 'badge-success';
        }
    }

    const shouldReceiveNewData = (nextData) => {
        console.log('New card has been added')
        setFilteredCards(nextData)
    }

    const handleDragStart = (cardId, laneId) => {
        console.log('drag started')
        console.log(`cardId: ${cardId}`)
        console.log(`laneId: ${laneId}`)
    }

    const handleDragEnd = (cardId, sourceLaneId, targetLaneId) => {
        console.log('drag end');

        // Encontra a lane de origem
        const sourceLane = filteredCards.lanes.find(lane => lane.id === sourceLaneId);
        console.log(`lane color: ${sourceLane.color}`);
        console.log(sourceLane);

        // Atualiza a cor do card no estado de forma imutável
        setFilteredCards(prevData => {
            const updatedLanes = prevData.lanes.map(lane => {
                if (lane.id === sourceLaneId) {
                    // Atualiza o card na lane de origem
                    const updatedCards = lane.cards.map(card =>
                        card.id === cardId ? { ...card, color: sourceLane.color } : card
                    );
                    return { ...lane, cards: updatedCards };
                }
                return lane;
            });

            return { ...prevData, lanes: updatedLanes };
        });
    };


    const settings = {
        editable: true,
        disallowAddingCard: true,
        draggable: true,
        droppable: true,
        //customCardLayout:true,
        customLaneLayout:true

    }
    // Filtra automaticamente ao iniciar o componente
    useEffect(() => {
        applyFilters(searchTerm, showArchived);

    }, []); // Executa somente na montagem do componente

    return data ? (
        <div>
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
                {/* Botão de Ver Arquivados / Ver Ativos */}
                <Button onClick={toggleShowArchived} variant="primary" style={{marginRight: '10px'}}>
                    {showArchived ? 'Ver Ativos' : 'Ver Arquivados'}
                </Button>

                {/* Botão de Criar Lane */}
                <Button
                    onClick={() => openModal("createLaneModal")}
                    style={{
                        padding: '10px 15px',
                        backgroundColor: '#28a745',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }}
                >
                    Criar Coluna
                </Button>

            </div>

            <Board
                data={filteredData}
                {...settings}

                components={{
                    LaneHeader: (laneProps) => (
                        <CustomLaneHeader

                            title={laneProps.title}
                            cards={laneProps.cards}
                            color={laneProps.color}
                            onSearch={(searchTerm) => handleSearch(laneProps.id, searchTerm)}
                        />
                    ),
                    AddCardLink: (props) => (
                        <CustomAddCardLink onClick={props.onClick} />
                    ),
                    Card: (props) => (
                        <CustomCard
                            handleTagColor={handleTagColor}
                            handleCloseModal={handleCloseModal}
                            handleSaveChanges={handleSaveChanges}
                            currentUserId={currentUserId}
                            closeModal={closeModal}
                            {...props}
                            onClick={() => handleCardClick(props.id, props, props.laneId)}
                        />
                    )

                }}
                style={{
                    backgroundColor: 'transparent',
                    overflowX: "auto"
                }}

                canLaneDrag={canLaneDrag}

                onCardMoveAcrossLanes={handleCardMove}

                handleDragStart={handleDragStart}
                handleDragEnd={handleDragEnd}
                handleLaneDrag={handleLaneDrag}
                onDataChange={shouldReceiveNewData}
                eventBusHandle={setEventBus}

            />

            {/* Modal para exibir e editar informações do card */}


            <div className="modal custom-modal fade" id="createLaneModal"
                 tabIndex="-1" aria-labelledby="createLaneModal" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title"
                                id="exampleModalLabel">Nova Coluna</h5>
                            <button className="btn-close" onClick={() => closeModal("createLaneModal")} type="button"
                                    data-bs-dismiss="modal" aria-label="Close"
                                    data-bs-original-title="" title=""></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSaveSubmit}
                                  className="form-bookmark needs-validation"
                                  id="bookmark-form" noValidate="">
                                <div className="row g-2">
                                    <div className="mt-0 mb-3 col-md-12">
                                        <label>Nome</label>
                                        <div
                                            className="row">
                                            <div
                                                className="col-sm-12">
                                                <input
                                                    className="form-control"
                                                    id="name"
                                                    name="name"
                                                    type="text"
                                                    required
                                                    placeholder="Por favor, digite o nome"
                                                    onChange={handleChangeNameLane}
                                                    data-bs-original-title=""
                                                    title="Por favor, digite o nome"/>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="mt-0 mb-3 col-md-12">
                                        <label>Cor da Coluna</label>

                                        <ChromePicker color={color} onChange={handleColorChange}/>
                                        <div style={{marginTop: '10px', display: 'flex', alignItems: 'center'}}>
                                            <div
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    backgroundColor: color,
                                                    border: '1px solid #000',
                                                    marginRight: '10px'
                                                }}
                                            />
                                            {/* Você pode opcionalmente manter o valor hexadecimal da cor ao lado */}
                                            <span>{color}</span>
                                        </div>
                                    </div>
                                </div>


                                <div
                                    className="d-flex align-items-center justify-content-center">
                                    <button
                                        style={{marginRight: 20}}
                                        className="btn btn-secondary update-contact"
                                        type="submit"
                                        data-bs-original-title=""
                                        title="">Salvar
                                    </button>
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() => closeModal("createLaneModal")}
                                        data-bs-dismiss="modal"
                                        data-bs-original-title=""
                                        title="">Cancelar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <Modal   show={showModal} onHide={handleCloseModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="media">
                            <ImageWithFallback
                                classImage={"img-70 img-fluid m-r-20 rounded-circle update_img_0"}
                                src={"https://via.placeholder.com/40"}
                                fallback={"https://via.placeholder.com/40"}
                                alt="contact"
                            />

                            <div>
                                <a className="font-w400 first_name_0">
                                    {selectedCard ? selectedCard.title : ''}
                                    <i className='bx bxl-whatsapp'></i>
                                </a>
                                <p className="email_add_0">{selectedCard ? selectedCard.phone : ''}</p>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={9}>

                            <div className="d-flex" >
                                <div style={{paddingRight: 15}}>
                                    <p style={{marginBottom: "-10px"}}>Membros</p>
                                    <ul className="user-list s2">
                                        { selectedCardUsers && selectedCardUsers.map((user) => (
                                            <li key={user.id}>
                                                <ImageWithFallback
                                                    src={user.avatar}
                                                    fallback={user.avatar_fallback}
                                                    title={user.name}
                                                    alt={`user${user.id}`}
                                                />
                                            </li> ))}

                                        {selectedCardUsersCount > 0 && (
                                            <li className="total">
                                                <span>+{selectedCardUsersCount}</span>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                <div style={{paddingRight: 15}}>
                                    <p style={{marginBottom: "0px", paddingRight: 70}}>Tags</p>
                                    <ul>
                                        <li>
                                            {/* <span className={`texto-limitado badge mb-3 ${getProcess(data.action.action.code_process_status)}`}>{data.action.action.label_process_status}</span>*/}
                                            <span
                                                className={`texto-limitado badge  badge-danger mb-3 `}>{"Reporvado"}</span>

                                        </li>
                                        <li>
                                            <span
                                                className={`texto-limitado badge  badge-success mb-3 `}>{"Aprovado"}</span>
                                        </li>

                                    </ul>
                                </div>
                                <div>
                                    <p style={{marginBottom: "0px"}}>Campanha</p>
                                    <span className={`texto-limitado badge  badge-info  `}>{"Campanha A"}</span>
                                </div>

                            </div>


                            <div style={{marginBottom: '20px', marginTop: '20px'}}>
                                <h5> Descrição</h5>
                                <Form.Control
                                    as="textarea"
                                    rows={4}
                                    value={editDescription}
                                    onChange={(e) => setEditDescription(e.target.value)}
                                    style={{resize: 'none'}}
                                />
                            </div>

                            {/* Seção de Tasks (Subtarefas) */}
                            <div style={{marginBottom: '20px'}}>
                                <h5> Checklist</h5>
                                <ProgressBar now={calculateProgress()} label={`${Math.round(calculateProgress())}%`}
                                             style={{marginBottom: '10px'}}/>

                                {tasks.length > 0 && <div style={{
                                    maxHeight: '200px',
                                    overflowY: 'auto',
                                    border: '1px solid #dee2e6',
                                    borderRadius: '4px',
                                    padding: '10px'
                                }}>
                                    <ListGroup>
                                        {tasks.map(task => (
                                            <ListGroup.Item key={task.id} style={{
                                                border: 'none',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                opacity: task.completed ? '0.6' : '1',
                                                textDecoration: task.completed ? 'line-through' : 'none'
                                            }} className="d-flex align-items-start">
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={task.completed}
                                                    label={task.text}
                                                    onChange={() => handleTaskToggle(task.id)}
                                                    style={{minWidth: "80%", cursor: 'pointer'}}
                                                />
                                                <div className="d-flex">
                                                    <div className="profilesmoll" style={{marginLeft: '10px'}}>
                                                     <span className="icon">
                                                        <a style={{color: "#000000"}} href="#" title="Editar"
                                                           onClick={() => handleEditTask(task.id)}>
                                                            <i className='bx bx-edit'></i>
                                                        </a>
                                                    </span>
                                                    </div>
                                                    <div className="profilesmoll" style={{marginLeft: '5px'}}>
                                                     <span className="icon">
                                                        <a style={{color: "#000000"}} href="#" title="Excluir"
                                                           onClick={() => handleDeleteTask(task.id)}>
                                                           <i className='bx bx-trash'></i>
                                                        </a>
                                                    </span>
                                                    </div>
                                                </div>


                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </div>}

                                <Form.Control
                                    type="text"
                                    placeholder="Adicionar nova tarefa..."
                                    value={newTask}
                                    onChange={(e) => setNewTask(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            handleAddTask();
                                        }
                                    }}
                                    style={{marginTop: '10px'}}
                                />
                                <Button variant="primary" onClick={handleAddTask} style={{marginTop: '10px'}}>
                                    Adicionar Tarefa
                                </Button>
                            </div>

                            {/* Seção de Comentários */}
                            {/*<div style={{ marginBottom: '20px' }}>

                            </div>*/}
                            {/* Seção de Comentários */}
                            <div style={{marginTop: '20px'}}>
                                <h5> Comentários</h5>
                                <div style={{maxHeight: '200px', overflowY: 'auto'}}>
                                    <ListGroup variant="flush">
                                        {comments.map(comment => (
                                            <ListGroup.Item key={comment.id} className="d-flex align-items-start">
                                                <Image
                                                    src={comment.userImage}
                                                    roundedCircle
                                                    style={{width: '40px', marginRight: '10px'}}
                                                />

                                                <div className="d-flex" style={{
                                                    background: '#f0f0f0',
                                                    padding: '10px',
                                                    borderRadius: '10px',
                                                    minWidth: "70%"
                                                }}>
                                                    <div>
                                                        <div className="justify-content-between pt-0 pb-0">
                                                            <strong>{comment.user}</strong>
                                                            <span style={{
                                                                color: '#666',
                                                                fontSize: '0.8rem',
                                                                paddingLeft: 10
                                                            }}>{comment.timestamp}</span>
                                                        </div>
                                                        <br/>
                                                        <div style={{whiteSpace: 'pre-wrap', flex: '1'}}>
                                                            {comment.text}
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="profilesmoll" style={{marginLeft: '10px'}}>
                                                     <span className="icon">
                                                        <a style={{color: "#000000"}} href="#" title="Editar"
                                                           onClick={() => handleEditComment(comment.id)}>
                                                            <i className='bx bx-edit'></i>
                                                        </a>
                                                    </span>
                                                    </div>
                                                    <div className="profilesmoll" style={{marginLeft: '5px'}}>
                                                     <span className="icon">
                                                        <a style={{color: "#000000"}} href="#" title="Excluir"
                                                           onClick={() => handleDeleteComment(comment.id)}>
                                                           <i className='bx bx-trash'></i>
                                                        </a>
                                                    </span>
                                                    </div>
                                                </div>


                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </div>

                                <Form.Control
                                    as="textarea"
                                    rows={2}
                                    placeholder="Escreva um comentário..."
                                    value={newComment}
                                    onChange={(e) => setNewComment(e.target.value)}
                                    style={{marginTop: '10px'}}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter' && !e.shiftKey) {
                                            e.preventDefault(); // Para evitar quebras de linha ao enviar o comentário
                                            handleAddComment();
                                        }
                                    }}
                                />
                                <Button variant="primary" onClick={handleAddComment} style={{marginTop: '10px'}}>
                                    Adicionar Comentário
                                </Button>
                            </div>
                        </Col>

                        <Col md={3}>
                            <h5>Ações</h5>
                            <Button variant="light" block onClick={() => alert('Mover o card')}>
                                Mover
                            </Button>
                            <Button variant="danger" block onClick={() => alert('Deletar o card')}>
                                Deletar
                            </Button>

                            {/* Seção de Membros */}
                            <div style={{marginTop: '20px'}}>
                                <h5>Membros</h5>
                                <ListGroup>
                                    {members.map((member, index) => (
                                        <ListGroup.Item key={index}>
                                            {member}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                                <Form.Control
                                    type="text"
                                    placeholder="Adicionar membro..."
                                    value={newMember}
                                    onChange={(e) => setNewMember(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            handleAddMember();
                                        }
                                    }}
                                    style={{marginTop: '10px'}}
                                />
                                <Button variant="primary" onClick={handleAddMember} style={{marginTop: '10px'}}>
                                    Adicionar Membro
                                </Button>
                            </div>

                            {/* Seção de Tags */}
                            <div style={{marginTop: '20px'}}>
                                <h5>Tags</h5>
                                <ListGroup>
                                    {tags.map((tag, index) => (
                                        <ListGroup.Item key={index}>
                                            {tag}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                                <Form.Control
                                    type="text"
                                    placeholder="Adicionar tag..."
                                    value={newTag}
                                    onChange={(e) => setNewTag(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            handleAddTag();
                                        }
                                    }}
                                    style={{marginTop: '10px'}}
                                />
                                <Button variant="primary" onClick={handleAddTag} style={{marginTop: '10px'}}>
                                    Adicionar Tag
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={handleSaveChanges}>
                        Salvar Alterações
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>


    ) : (
        <div>Carregando...</div>
    );
};



export default KanbanBoard;