import React from 'react';
import axios from 'axios';
import Loader from '../Loader';
import ImageWithFallback from '../ImageWithFallback';
export default class AllChats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentChat: null,
            idSelectChatPhonebook: 0,
            inputSearch: '',
            chatList: [],
            chatSearch: [],
        };
    }
    handleChatItemClick = (chat) => {
        this.props.onChatSelect(chat);
        this.setState({ currentChat: chat });
    }

    handleSendSearch = async () => {

        const {inputSearch} = this.state;
        this.props.onSelectSendSearch(true);

        let response = await axios.get('/messagings/get_chat_list.json', {
            params: {
                instance_id: this.props.instance_id,
                inputSearch: inputSearch,
                type: "inputSearch",
                chat_type_search: "allChat"
            }
        });

        this.props.onSendSearch(response.data.chats,"allChat");


    }
    handleInputClick = () => {

        this.setState({ inputSearch: ''});
        this.props.onSelectSendSearch(false);
        this.getchats()

    }
    getchats = async () => {

        let response = await axios.get('/messagings/get_chat_list.json', {
            params: {
                instance_id: this.props.instance_id,
                type: "get_all_chat_list"
            }
        });


        this.props.onSendSearch(response.data.chats,"allChat");

    }
    handleInputChange = (event) => {
        this.setState({ inputSearch: event.target.value });
    }
    seletedChatItem = (chat) => {
        const {chats} = this.props
        this.props.onChatSelect(chat);
        this.setState({ currentChat: chat });

        // Atualizar o estado de chats
        const updatedChats = chats.map(chatItem =>
            chatItem.id === chat.id ? { ...chatItem, countUnRead: 0 } : chatItem
        );

        // Aqui você precisaria de um método para atualizar o estado do chats,
        // talvez algo como:
        this.props.updateChats(updatedChats,"allChat");

    }
    componentDidMount() {
        const {selectChatPhonebook, chats} = this.props

        if(selectChatPhonebook){
            this.setState({ idSelectChatPhonebook: selectChatPhonebook });

            const chat = chats.find(chat => chat.id === selectChatPhonebook);

            if (chat) {
                this.setState({ currentChat: chat });
                this.seletedChatItem(chat);
            }


        }
    }

    componentWillUnmount() {

    }
    render() {
        const {currentChat, inputSearch, chatSearch, idSelectChatPhonebook} = this.state;
        let {chats, messagesUnread, selectChatPhonebook, image_avatar,loader} = this.props



        return (<>
            <div style={{marginBottom: 10}}></div>

            <div className="input-group search-area">
                <span onClick={this.handleSendSearch} className="input-group-text"><a href="#"><i
                    className="bx bx-search"></i></a></span>
                <input onClick={this.handleInputClick} type="text" onChange={this.handleInputChange}
                       value={inputSearch} className="form-control" placeholder="Buscar"></input>

            </div>

            <div style={{marginBottom: 5}}></div>

            <div className="common-space">
                <p>Conversas recentes</p>
                {/*<div className="header-top"><a className="btn badge-primary f-w-500" href="#!"><i
                    className="bx bxs-contact"></i></a></div>*/}
            </div>
            {chats.length === 0  && <div className="mt-30 chats-user d-flex center">
                <p>Sem conversas recentes...</p>
            </div>}
            {chats.length > 0  &&  <ul className="chats-user">

                {chats.map((chat) => (
                    <li key={chat.id}
                        onClick={() => this.handleChatItemClick(chat)}
                        className={`common-space ${(currentChat?.id === chat.id || idSelectChatPhonebook === chat.id) ? 'active' : ''}`}>

                            {chat.id === idSelectChatPhonebook  && currentChat === null && this.seletedChatItem(chat)}
                            <div className="chat-time">
                                <div className="active-profile">
                                    <ImageWithFallback
                                        classImage={"img-fluid rounded-circle"}
                                        src={chat.avatar}
                                        fallback={image_avatar}
                                        alt="user"
                                    />

                                    <div className="status bg-success"></div>
                                </div>
                                <div>
                                    <span>{chat.title}</span>
                                    <p className="texto-limitado">{this.props.lastMessageChat(chat.lastTypeMessage,chat.lastMessage)}</p>
                                </div>
                            </div>
                            <div>
                                {chat.countUnRead > 0 &&
                                    <>
                                        <p>{chat.dateLastMessage} </p>
                                        <div className="badge badge-light-success">{chat.countUnRead}</div>
                                    </>
                                }
                            </div>


                    </li>
                ))}


            </ul>}

        </>);
    }
}